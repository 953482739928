import React from 'react';

import { ALL_GAME_ID, api } from 'api';

import { Icon } from 'components/Icons';
import { GameIcon } from 'components/Icons__deprecated';
import { SelectForm } from 'components/SelectForm';

import { useText, useTranslations } from 'text';

import css from './EventBasicForm.module.css';
import { GAME_MODES_BY_GAME, EVENTS_TYPE } from './helpers';

import type { SupportedGameId } from 'api/types';

interface SelectProps<T, O> {
  label: string;
  value: api.Maybe<T>;
  disabled?: boolean;
  onChange: (value: O) => void;
  gridSize?: number;
  required?: boolean;
}

export type GameSelectValue = SupportedGameId | 'Home';

type GameSelectOption = {
  value: GameSelectValue;
  label: string;
  renderImage: () => React.ReactElement;
};

type GameSelectProps = SelectProps<GameSelectValue, api.Maybe<GameSelectValue>> & {
  withHome?: boolean;
};

export const HomeGameSelect = React.memo<GameSelectProps>(
  ({ label, value, required, onChange, disabled, gridSize, withHome }) => {
    const text = useTranslations();
    const navText = useText(state => state.controls.navigation);

    const gameOptions = ALL_GAME_ID.map(gameId => ({
      value: gameId,
      label: text.gameTitle(gameId).full,
      renderImage: () => (
        <GameIcon className={css.gameIcon} gameId={gameId} size={32} />
      ),
    }));

    const homeOption: GameSelectOption = {
      value: 'Home',
      label: navText.home,
      renderImage: () => <Icon.Home className={css.homeIcon} />,
    };

    const options: GameSelectOption[] = withHome
      ? [homeOption, ...gameOptions]
      : gameOptions;

    return (
      <SelectForm
        isSpecial
        disabled={disabled}
        gridSize={gridSize}
        label={label}
        options={options}
        renderSpecialIndicator={() =>
          options.find(option => option.value === value)?.renderImage() || null
        }
        required={required}
        selectClassName={withHome ? css.gameSelectWithHome : undefined}
        value={value}
        onChange={onChange}
      />
    );
  },
);

export const GameSelect = React.memo<SelectProps<api.GameId, api.Maybe<api.GameId>>>(
  ({ label, value, required, onChange, disabled, gridSize }) => {
    const text = useTranslations();

    const options = ALL_GAME_ID.map(gameId => ({
      value: gameId,
      label: text.gameTitle(gameId).full,
      renderImage: () => (
        <GameIcon className={css.gameIcon} gameId={gameId} size={32} />
      ),
    }));

    return (
      <SelectForm
        isSpecial
        disabled={disabled}
        gridSize={gridSize}
        label={label}
        options={options}
        renderSpecialIndicator={() =>
          options.find(option => option.value === value)?.renderImage() || null
        }
        required={required}
        value={value}
        onChange={onChange}
      />
    );
  },
);

type Type = {
  gameMode: api.Maybe<api.GameMode>;
  eventType: api.Maybe<api.EventType>;
};

type TypeProps = SelectProps<api.Maybe<api.EventType | api.GameMode>, Type> & {
  gameId: api.Maybe<api.GameId>;
  isStreamer?: boolean;
};

export const TypeSelect = React.memo<TypeProps>(
  ({ label, gameId, value, required, disabled, onChange, gridSize, isStreamer }) => {
    const text = useTranslations();

    const gameModeOptions = (GAME_MODES_BY_GAME[gameId as SupportedGameId] || []).map(
      gameMode => ({
        value: gameMode,
        label: text.gameMode(gameMode).usual,
      }),
    );

    const eventTypes = EVENTS_TYPE[gameId as SupportedGameId] || [
      api.EventType.Event,
    ];

    const eventTypeOptions = eventTypes.map(eventType => ({
      value: eventType,
      label: text.eventType(eventType),
    }));

    const isRelevantValue =
      GAME_MODES_BY_GAME[gameId as SupportedGameId]?.includes(value as any) ||
      value !== text.eventType(api.EventType.Waitlist) ||
      value !== text.eventType(api.EventType.Public);

    return (
      <SelectForm
        disabled={disabled}
        gridSize={gridSize}
        label={label}
        options={[...gameModeOptions, ...eventTypeOptions]}
        required={required}
        value={isRelevantValue ? value : undefined}
        onChange={value => {
          if (!value || !gameId) {
            return;
          }
          if (GAME_MODES_BY_GAME[gameId as SupportedGameId]?.includes(value as any)) {
            if (value === api.GameMode.Custom) {
              return onChange({
                gameMode: value as api.GameMode,
                eventType: api.EventType.Event,
              });
            }

            const type = api.EventType.Event;
            onChange({ gameMode: value as api.GameMode, eventType: type });
          } else {
            onChange({ eventType: value as api.EventType, gameMode: undefined });
          }
        }}
      />
    );
  },
);
