import React, { useMemo } from 'react';

import cx from 'classnames';

import { Icon } from 'components/Icons';
import { Text } from 'components/Text';

import { useText } from 'text';

import { LayoutApp, LayoutAppProps } from '../LayoutApp';

import { ContentError } from './ContentError';
import { ContentLoading } from './ContentLoading';
import { ContentTab, ContentTabs } from './ContentTabs';
import { FixedFooter } from './FixedFooter';
import { DEFAULT_BACKGROUND_IMG } from './helpers';

import css from './LayoutColumns.module.css';

import type { ApolloError } from '@apollo/client';

export interface LayoutColumnsProps extends LayoutAppProps {
  renderRightSection: React.ReactNode;

  backgroundImg?: string;

  /* A set of tabs for floating navigation bar */
  tabs: ContentTab[];
  activeTab?: string | null;

  error?: ApolloError;
  isLoading?: boolean;
  canEdit?: boolean;

  renderFooter?: React.ReactNode;
  wrapperClassName?: string;
}

export const LayoutColumns: React.FC<LayoutColumnsProps> = ({
  children,
  renderRightSection,
  backgroundImg = DEFAULT_BACKGROUND_IMG,

  isLoading = false,
  error,

  tabs,
  activeTab,
  canEdit = false,

  renderFooter,
  wrapperClassName,

  ...props
}) => {
  const text = useText(state => state.controls);

  const backgroundStyle = useMemo<React.CSSProperties>(
    () => ({ backgroundImage: `var(--gradient-radial), url("${backgroundImg}")` }),
    [backgroundImg],
  );

  return (
    <LayoutApp {...props}>
      <section className={css.container}>
        <div className={css.wrapper}>
          <section className={css.columnCenterContainer} id="layout_contect">
            <div className={css.columnCenterBackgroundImgWrapper}>
              <figure
                className={css.columnCenterBackgroundImg}
                style={backgroundStyle}
              />
              {canEdit && !backgroundImg ? (
                <div className={css.editHeader}>
                  <Icon.Gallery />
                  <Text.R3Memo semibold Tag="span">
                    {text.actions.clickToUploadCover}
                  </Text.R3Memo>
                </div>
              ) : null}
            </div>
            <header className={css.columnCenterTabsContainer}>
              <ContentTabs activeTab={activeTab} tabs={tabs} />
            </header>

            <section
              className={cx(css.columnCenterWrapper, wrapperClassName, {
                [css.wrapperWithFooter]: Boolean(renderFooter),
              })}
            >
              {!isLoading ? children : null}
              {isLoading ? <ContentLoading /> : null}
              {error ? <ContentError error={error} /> : null}
            </section>
            {renderFooter ? <FixedFooter>{renderFooter}</FixedFooter> : null}
          </section>

          <aside className={css.columnRightContainer}>
            <section className={css.columnRightWrapper}>{renderRightSection}</section>
          </aside>
        </div>
      </section>
    </LayoutApp>
  );
};
