import React from 'react';

import css from './WrapperStickyHeader.module.css';

interface WrapperStickyHeaderProps {
  children: React.ReactNode;
}

export const WrapperStickyHeader: React.FC<WrapperStickyHeaderProps> = ({
  children,
}) => {
  return <section className={css.container}>{children}</section>;
};
