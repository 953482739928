import React, { useRef, useState } from 'react';

import cx from 'classnames';

import { analytics } from 'analytics';
import { api, refetchMe, refetchMyGames } from 'api';

import { utils } from 'utils';

import { Button } from '../Button';
import { Icon } from '../Icons';
import { useTranslations, useText } from '../Language';

import { List } from '../List';
import { useToasts } from '../Toast';

import css from './ButtonSocial.module.css';
import {
  authUserByToken,
  ButtonSocialProps,
  HandlerWithGamesProps,
  WAIT_FOR_GAMES_MS,
} from './helpers';

type BattlenetProps = ButtonSocialProps & HandlerWithGamesProps;

export const ButtonSocialBattlenetConnect: React.FC<BattlenetProps> = ({
  colored = true,
  withIcon = true,
  disabled = false,
  small = false,
  label,
  onClick = () => undefined,
  onError = () => undefined,
  ...props
}) => {
  const text = useText(state => state.controls.loginModal);
  const toasts = useToasts();

  const [getMyGames] = api.useGetMyGamesLazyQuery();

  const [connectBattlenet] = api.useConnectBattlenetMutation({
    onError: error => {
      toasts.showError(error, api.AuthBattlenetDocument);
      onError();
    },
    refetchQueries: [refetchMe, refetchMyGames],
    onCompleted: () => {
      if (!props.onComplete) {
        return;
      }
      if (props.refetchGames) {
        const promisedGames = utils
          .delay(props.loadingDelay || WAIT_FOR_GAMES_MS)
          .then(() => getMyGames())
          .then(data => data.data?.getMyGames as api.Maybe<api.Game[]>);

        return props.onComplete(promisedGames);
      }

      props.onComplete();
    },
  });

  return (
    <BattlenetButtonContainer
      className={cx(css.buttonContainer, {
        [css.battlenetColored]: colored,
        [css.smallContainer]: small,
      })}
      disabled={disabled}
      withIcon={withIcon}
      onCancel={onError}
      onRegionCommit={data => connectBattlenet({ variables: { data } })}
      onSyntheticClick={onClick}
    >
      {label || `${text.title} Battle.net`}
    </BattlenetButtonContainer>
  );
};

export const ButtonSocialBattlenetAuth: React.FC<BattlenetProps> = ({
  colored = true,
  withIcon = true,
  disabled = false,
  isReconnection = false,
  onClick = () => undefined,
  onError = () => undefined,
  ...props
}) => {
  const text = useText(state => state.controls.loginModal);
  const toasts = useToasts();

  const [getMyGames] = api.useGetMyGamesLazyQuery();

  const [authBattlenet, { client }] = api.useAuthBattlenetMutation({
    update: (_, response) =>
      authUserByToken(client, response.data?.authBattlenet.token),
    onError: error => {
      analytics.loginCompleteError('battlenet');
      toasts.showError(error, api.AuthBattlenetDocument);
      onError();
    },
    onCompleted: () => {
      if (!props.onComplete) {
        return;
      }
      analytics.loginCompleteSuccess('battlenet');
      if (props.refetchGames) {
        const promisedGames = utils
          .delay(props.loadingDelay || WAIT_FOR_GAMES_MS)
          .then(() => getMyGames())
          .then(data => data.data?.getMyGames as api.Maybe<api.Game[]>);

        return props.onComplete(promisedGames);
      }

      props.onComplete();
    },
  });

  return (
    <BattlenetButtonContainer
      className={cx(css.buttonContainer, {
        [css.battlenetColored]: colored,
        [css.battlenetWithoutIcon]: !withIcon,
      })}
      disabled={disabled}
      withIcon={withIcon}
      onCancel={onError}
      onRegionCommit={data => authBattlenet({ variables: { data } })}
      onSyntheticClick={onClick}
    >
      {isReconnection
        ? `${text.reconnection('Battle.net')} `
        : `${text.title} Battle.net`}
    </BattlenetButtonContainer>
  );
};

interface BattlenetButtonProps {
  onRegionCommit: (region: api.OAuthData) => unknown | Promise<unknown>;
  onSyntheticClick?: (event: React.MouseEvent) => unknown | Promise<unknown>;
  onCancel?: () => unknown;
  children: React.ReactChild;
  disabled?: boolean;
  className?: string;
  withIcon?: boolean;
}
const BattlenetButtonContainer: React.FC<BattlenetButtonProps> = ({
  onRegionCommit,
  children,
  disabled,
  className,
  withIcon,
  onSyntheticClick = () => undefined,
  onCancel = () => undefined,
}) => {
  const text = useTranslations();

  const ref = useRef<HTMLDivElement | null>(null);

  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  utils.useOnClickOutside(ref, () => setIsSelectOpen(false));

  const regions: api.BattlenetRegion[] = [
    api.BattlenetRegion.Europe,
    api.BattlenetRegion.Us,
  ];

  return (
    <div ref={ref} className={css.selectContainer}>
      <Button
        className={className}
        color="secondary"
        disabled={disabled || isLoading}
        Icon={withIcon ? Icon.Battlenet : undefined}
        loading={isLoading}
        onClick={async event => {
          await onSyntheticClick(event);
          if (event.defaultPrevented) {
            return;
          }
          setIsSelectOpen(!isSelectOpen);
        }}
      >
        {children}
      </Button>
      <List
        className={cx(css.select, { [css.selectOpen]: isSelectOpen })}
        items={regions}
        renderItem={region => (
          <div key={region} className={css.option}>
            {text.wow.region(region)} Battle.net
          </div>
        )}
        onSelectItem={async region => {
          setIsLoading(true);
          setIsSelectOpen(false);
          try {
            const data = await utils.getBattlenetAuthData(region);
            await onRegionCommit(data);
          } catch (error) {
            onCancel();
          }
          setIsLoading(false);
        }}
      />
    </div>
  );
};
