import type { SupportedGameId } from 'api/types';

// Image is used in headers of game feeds and in game previews
export const GAME_BACKGROUND_URLS = Object.freeze({
  WorldOfWarcraft: 'https://storage.googleapis.com/lfgcdn/assets/bg2/wow.webp',
  LeagueOfLegends: 'https://storage.googleapis.com/lfgcdn/assets/bg2/lol.webp?1',
  LostArkEn: 'https://storage.googleapis.com/lfgcdn/assets/bg2/lost-ark.webp',
  CSGO: 'https://storage.googleapis.com/lfgcdn/assets/bg2/csgo.webp',
  PUBGMobile:
    'https://storage.googleapis.com/lfgcdn/assets/bg2/pubg-mobile-dark.webp',
  CODMobile: 'https://storage.googleapis.com/lfgcdn/assets/bg2/cod.webp',
  Standoff2: 'https://storage.googleapis.com/lfgcdn/assets/bg3/standoff2.webp',
  Hearthstone: 'https://storage.googleapis.com/lfgcdn/assets/bg2/hearthstone.webp',
  Custom: 'https://storage.googleapis.com/lfgcdn/assets/bg2/custom.webp',
  GooseGooseDuck: 'https://storage.googleapis.com/lfgcdn/assets/bg2/ggd-dark.webp',
  ShadowFight3:
    'https://storage.googleapis.com/lfgcdn/assets/bg2/shadow-fight-3.webp',
  ShadowFight4Arena:
    'https://storage.googleapis.com/lfgcdn/assets/bg2/shadow-fight-4.webp',
  DawnOfZombiesSurvival:
    'https://storage.googleapis.com/lfgcdn/assets/bg2/doz-survival.webp',
  Crossfire: 'https://storage.googleapis.com/lfgcdn/assets/bg2/crossout-mobile.webp',
  NewStateMobile:
    'https://storage.googleapis.com/lfgcdn/assets/bg2/new-state-mobile.webp',
  RiseOfEmpires:
    'https://storage.googleapis.com/lfgcdn/assets/bg2/rise-of-empires.webp',
  ModernStrikeOnlinePvPFPS:
    'https://storage.googleapis.com/lfgcdn/assets/bg2/modern-strike-online.webp',
  RiseOfKingdomsLostCrusade:
    'https://storage.googleapis.com/lfgcdn/assets/bg2/rise-of-kingdoms.webp',
  IdleHeroes: 'https://storage.googleapis.com/lfgcdn/assets/bg2/idle-heroes.webp',
  LastDayOnEarthSurvival:
    'https://storage.googleapis.com/lfgcdn/assets/bg2/last-day-on-earth.webp',
  MobileLegendsAdventure:
    'https://storage.googleapis.com/lfgcdn/assets/bg2/mobile-legends-adventure.webp',
  STALKER2HeartOfChornobyl:
    'https://storage.googleapis.com/lfgcdn/assets/bg2/stalker-2.webp',
  Highrise: 'https://storage.googleapis.com/lfgcdn/assets/bg2/highrise.webp',
  AFKArena: 'https://storage.googleapis.com/lfgcdn/assets/bg2/afk-arena.webp',
}) satisfies Record<SupportedGameId, string>;

export const DEFAULT_BACKGROUND_URL =
  'https://storage.googleapis.com/lfgcdn/assets/bg2/default-bg.webp';
