import React from 'react';

import { useRouter } from 'next/router';

import {
  getActiveTabLink,
  LayoutColumns,
  LayoutColumnsProps,
} from '../LayoutColumns';

import { FeedSideBanners as FeedRightBanners } from './FeedRightBanners';
import { getBackgroundUrl, useGameTabs } from './helpers';
import css from './LayoutGameFeed.module.css';

import type { api } from 'api';
import type { SupportedGameId } from 'api/types';

type ParentProps = Omit<
  LayoutColumnsProps,
  'backgroundImg' | 'renderRightSection' | 'tabs' | 'activeTab'
>;

interface LayoutGameFeedProps extends ParentProps {
  // Applies game background, decids upon right column content
  gameId: api.Maybe<SupportedGameId>;

  withChat?: boolean /* To be removed */;
  renderBanners?: () => React.ReactNode;
}

export const LayoutGameFeed: React.FC<LayoutGameFeedProps> = ({
  children,
  gameId,

  withChat,
  renderBanners = () => null,

  ...props
}) => {
  const router = useRouter();
  const tabs = useGameTabs(gameId);

  const renderRightSection = () => {
    if (!withChat) {
      return (
        <div className={css.banners}>
          <FeedRightBanners gameId={gameId} />
          {renderBanners()}
        </div>
      );
    }

    return null;
  };

  return (
    <LayoutColumns
      activeTab={getActiveTabLink(tabs, router)}
      backgroundImg={getBackgroundUrl(gameId)}
      renderRightSection={renderRightSection()}
      tabs={tabs}
      {...props}
    >
      {children}
    </LayoutColumns>
  );
};
