import { useEffect, useRef } from 'react';

import { useOnboardingModal } from 'components/ContextModal/onboarding';
import { Slide } from 'containers/ModalAuthOnboarding/helpers';

import { GAME_SLUGS } from 'utils/location';
import { cookie, shouldOpenOnboarding } from 'utils/main';

import type { api } from 'api';
import type { SupportedGameId } from 'api/types';

export const DEFAULT_BACKGROUND_IMG =
  'https://storage.googleapis.com/lfgcdn/assets/bg3/shared.webp';

const COOKIE_ONBOARDING_VISITED_KEY = (game: SupportedGameId) =>
  `onboarding_visited_${GAME_SLUGS[game]}`;

interface PersistentOnboardingArgs {
  game: SupportedGameId | undefined;
  me: api.Maybe<api.User>;
  lfgs: api.Maybe<api.Lfg[]>;
}

export function usePersistentOnboarding({
  game,
  me,
  lfgs,
}: PersistentOnboardingArgs) {
  const onboardingModal = useOnboardingModal();
  const deprecatedref = useRef(false);

  useEffect(() => {
    if (!game) {
      return;
    }

    shouldOpenOnboarding(
      me,
      game,
      lfgs,
      () => {
        const getCookie = () => cookie.get(COOKIE_ONBOARDING_VISITED_KEY(game));
        const setCookie = () =>
          cookie.set(COOKIE_ONBOARDING_VISITED_KEY(game), '1', 0.5);

        if (getCookie()) {
          return;
        }

        setCookie();
        onboardingModal.open({
          initialGame: game,
          canBeClosed: true,
          slides: [Slide.GameBlocks, Slide.GameSettings],
        });
      },
      deprecatedref,
    );
  }, [game, me, lfgs]);
}
