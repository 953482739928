import React from 'react';

import { LoaderBouncing } from 'components/Loaders';
import { Text } from 'components/Text';

import { useText } from 'text';

import css from './ContentLoading.module.css';

export const ContentLoading = React.memo(() => {
  const text = useText(state => state.controls);

  return (
    <article className={css.container}>
      <LoaderBouncing color="var(--dark-64)" size={24} />
      <Text.R3Memo className={css.loadingText}>{text.loading}</Text.R3Memo>
    </article>
  );
});
