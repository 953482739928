import React from 'react';

import { OnboardingCardList } from 'components/OnboardingCardList';

import css from './FeedSideBanners.module.css';

import type { SupportedGameId } from 'api/types';

interface FeedSideBannersProps {
  gameId: SupportedGameId | undefined;
}

export const FeedSideBanners = React.memo<FeedSideBannersProps>(({}) => {
  return (
    <div className={css.bannersContainer}>
      {/* <OnboardingCreateGroupBanner
        communityName={gameId ? translation.gameTitle(gameId).short : undefined}
        description={welcomeCardDesc}
      /> */}
      <OnboardingCardList />
      {/* <OnboardingAddCommentBanner /> */}
    </div>
  );
});
