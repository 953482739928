import React from 'react';

import cx from 'classnames';

import { api, refetchMe } from 'api';
import { useApp } from 'components/ContextApp';
import { utils } from 'utils';

import { Button } from '../Button';
import { Icon } from '../Icons';
import { useText } from '../Language';
import { useToasts } from '../Toast';

import css from './ButtonSocial.module.css';
import { authUserByToken, ButtonSocialProps, HandlerProps } from './helpers';

type DiscordProps = ButtonSocialProps & HandlerProps;

export const ButtonSocialDiscordAuth: React.FC<DiscordProps> = ({
  colored = true,
  withIcon = true,
  disabled = false,
  onClick = () => undefined,
  onComplete = () => undefined,
  onError = () => undefined,
}) => {
  const text = useText(state => state.controls.loginModal);
  const toasts = useToasts();
  const app = useApp();

  const [authDiscord, { client }] = api.useAuthDiscordMutation({
    update: (_, response) =>
      authUserByToken(client, response.data?.authDiscord.token),
    onError: error => {
      toasts.showError(error, api.AuthDiscordDocument);
      onError();
    },
    onCompleted: onComplete,
  });

  return (
    <Button
      className={cx(css.buttonContainer, { [css.discordColored]: colored })}
      color="secondary"
      disabled={disabled}
      Icon={withIcon ? Icon.Discord : undefined}
      onClick={async event => {
        await onClick(event);
        if (event.defaultPrevented) {
          return; // Interrupted with event.preventDefault()
        }

        if (app.isMobile) {
          // redirect to discord link
          window.location.replace(utils.getDiscordAuthLink());
        } else {
          // desktop flow, open in separate window
          try {
            const authData = await utils.getDiscordAuthData();
            await authDiscord({ variables: { data: authData } });
          } catch (error) {
            onError();
          }
          utils.authStorage.discord.resetSession();
        }
      }}
    >
      {`${text.title} Discord`}
    </Button>
  );
};

export const ButtonSocialDiscordConnect: React.FC<DiscordProps> = ({
  colored = true,
  withIcon = true,
  disabled = false,
  onClick = () => undefined,
  onComplete = () => undefined,
  onError = () => undefined,
}) => {
  const text = useText(state => state.controls.loginModal);
  const toasts = useToasts();

  const [connectDiscord] = api.useConnectDiscordMutation({
    onError: error => {
      toasts.showError(error, api.ConnectDiscordDocument);
      onError();
    },
    refetchQueries: [refetchMe],
    onCompleted: onComplete,
  });

  return (
    <Button
      className={cx(css.buttonContainer, { [css.discordColored]: colored })}
      color="secondary"
      disabled={disabled}
      Icon={withIcon ? Icon.Discord : undefined}
      onClick={async event => {
        await onClick(event);
        if (event.defaultPrevented) {
          return; // Interrupted with event.preventDefault()
        }

        try {
          const authData = await utils.getDiscordAuthData();
          await connectDiscord({ variables: { data: authData } });
        } catch (error) {
          onError();
        }
        utils.authStorage.discord.resetSession();
      }}
    >
      {`${text.title} Discord`}
    </Button>
  );
};
