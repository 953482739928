import type { NextRouter } from 'next/router';
import type { UrlObject } from 'url';

export interface ContentTab {
  label: string;
  link: UrlObject;
  onClick?: (event: React.MouseEvent) => void;
}

export function getActiveTabLink(
  tabs: ContentTab[],
  router: NextRouter,
): string | null {
  const { asPath } = router;

  const pathname = asPath.split('?')[0];
  const activeTab = tabs.find(
    ({ link }) => link.pathname && pathname === link.pathname,
  );
  if (activeTab) {
    return activeTab.link.pathname || null;
  }

  return null;
}
