import React, { useMemo } from 'react';

import cx from 'classnames';

import { analytics } from 'analytics';
import { LoginMethod } from 'analytics/header';
import { api } from 'api';

import { Button } from 'components/Button';
import {
  ButtonSocialBattlenetAuth,
  ButtonSocialDiscordAuth,
  ButtonSocialTwitchAuth,
} from 'components/ButtonSocial';
import { ButtonSocialFacebookAuth } from 'components/ButtonSocial/Facebook.container';
import { ButtonSocialGoogleAuth } from 'components/ButtonSocial/Google.container';

import { useText } from 'text';

import css from './AuthButtons.module.css';

import type { SupportedGameId } from 'api/types';

export type AuthButtonsOrder = (LoginMethod | '/')[];

interface AuthButtonsProps {
  isLoading?: boolean;
  initialGame?: SupportedGameId;
  allAuthButtonsVisible?: boolean;
  buttonsContainerClassName?: string;
  authButtonsOrder?: AuthButtonsOrder;

  setAllAuthButtonsVisible?: (value: boolean) => void;
  handleClickAuth?: (
    vendor: LoginMethod,
    event?: React.MouseEvent<Element, MouseEvent>,
  ) => void;
  handleAuthError?: (vendor: LoginMethod) => void;
  handleAuthComplete?: (vendor: LoginMethod) => void;
}

export const AuthButtons: React.FC<AuthButtonsProps> = ({
  isLoading = false,
  initialGame,
  authButtonsOrder,
  allAuthButtonsVisible = true,
  buttonsContainerClassName,
  setAllAuthButtonsVisible = () => undefined,
  handleClickAuth = () => undefined,
  handleAuthError = () => undefined,
  handleAuthComplete = () => undefined,
}) => {
  const text = useText(state => state.modalAuthOnboarding.loginScenario);
  const defaultAuthButtonsOrder: AuthButtonsOrder =
    initialGame === api.GameId.WorldOfWarcraft
      ? ['discord', 'battlenet', '/', 'twitch', 'google']
      : ['discord', 'google', '/', 'facebook', 'twitch'];

  const buttonsOrder = useMemo(() => {
    return authButtonsOrder || defaultAuthButtonsOrder;
  }, [authButtonsOrder, defaultAuthButtonsOrder]);

  const maxHeight = useMemo(() => {
    if (allAuthButtonsVisible) {
      return `${400}px`;
    }

    const slashIndex = buttonsOrder.indexOf('/');

    if (slashIndex === -1) {
      return `${400}px`;
    }

    return `${slashIndex * 38 + (slashIndex - 1) * 8 + 40}px`;
  }, [buttonsOrder, allAuthButtonsVisible]);

  return (
    <article
      className={cx(css.buttonsWrapper, buttonsContainerClassName, {
        [css.buttonsWrapperVisible]: allAuthButtonsVisible,
      })}
      style={{ maxHeight }}
    >
      {buttonsOrder.map((authMethod, index) => {
        if (authMethod === '/') {
          return (
            !allAuthButtonsVisible && (
              <Button
                key={index}
                className={css.buttonShowMore}
                color="transparent"
                disabled={isLoading}
                onClick={() => {
                  setAllAuthButtonsVisible(true);
                  analytics.clickOtherOptions();
                }}
              >
                {text.actionOtherOptions}
              </Button>
            )
          );
        }

        switch (authMethod) {
          case 'battlenet':
            return (
              <ButtonSocialBattlenetAuth
                key={index}
                colored={index === 0}
                disabled={isLoading}
                onClick={event => {
                  handleClickAuth('battlenet', event);
                }}
              />
            );
          case 'discord':
            return (
              <ButtonSocialDiscordAuth
                key={index}
                colored={index === 0}
                disabled={isLoading}
                onClick={() => {
                  handleClickAuth('discord');
                }}
                onComplete={() => {
                  handleAuthComplete('discord');
                }}
                onError={() => {
                  handleAuthError('discord');
                }}
              />
            );
          case 'twitch':
            return (
              <ButtonSocialTwitchAuth
                key={index}
                colored={index === 0}
                disabled={isLoading}
                onClick={() => {
                  handleClickAuth('twitch');
                }}
                onComplete={() => {
                  handleAuthComplete('twitch');
                }}
                onError={() => {
                  handleAuthError('twitch');
                }}
              />
            );
          case 'google':
            return (
              <ButtonSocialGoogleAuth
                key={index}
                colored={index === 0}
                disabled={isLoading}
                onClick={() => {
                  handleClickAuth('google');
                }}
                onComplete={() => {
                  handleAuthComplete('google');
                }}
                onError={() => {
                  handleAuthError('google');
                }}
              />
            );
          case 'facebook':
            return (
              <ButtonSocialFacebookAuth
                key={index}
                colored={index === 0}
                disabled={isLoading}
                onClick={() => {
                  handleClickAuth('facebook');
                }}
                onComplete={() => {
                  handleAuthComplete('facebook');
                }}
                onError={() => {
                  handleAuthError('facebook');
                }}
              />
            );
        }
      })}
    </article>
  );
};
