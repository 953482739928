import React from 'react';

import cx from 'classnames';
import Link from 'next/link';

import { Text } from 'components/Text';

import css from './ContentTabs.module.css';
import { ContentTab } from './helpers';

interface ContentTabsProps {
  tabs: ContentTab[];
  activeTab?: string | null;
}

export const ContentTabs = React.memo<ContentTabsProps>(({ tabs, activeTab }) => {
  return (
    <nav className={css.container}>
      {tabs.map(tab => (
        <Link
          key={tab.link.pathname}
          className={cx(css.linkContainer, {
            [css.linkContainerActive]: activeTab === tab.link.pathname,
            [css.greenTab]: tab.link.pathname?.includes('/top'),
          })}
          href={tab.link}
          onClick={tab.onClick}
        >
          <Text.R3 semibold className={css.linkText}>
            {tab.label}
          </Text.R3>
        </Link>
      ))}
    </nav>
  );
});
