import { useMemo } from 'react';

import { analytics } from 'analytics';
import { api } from 'api';
import { useText } from 'text';
import { links } from 'utils/links';

import { ContentTab } from '../LayoutColumns';

import type { SupportedGameId } from 'api/types';

/**
 * Background image by game for all feed pages as groups
 */
const BACKGROUND_IMGS: Record<SupportedGameId, string> = {
  WorldOfWarcraft: 'https://storage.googleapis.com/lfgcdn/assets/bg3/wow.webp',
  LeagueOfLegends: 'https://storage.googleapis.com/lfgcdn/assets/bg3/lol.webp',
  LostArkEn: 'https://storage.googleapis.com/lfgcdn/assets/bg3/lost-ark.webp',
  // Update
  CSGO: 'https://storage.googleapis.com/lfgcdn/assets/bg3/csgo.webp',
  Hearthstone: 'https://storage.googleapis.com/lfgcdn/assets/bg3/hearthstone.webp',
  PUBGMobile:
    'https://storage.googleapis.com/lfgcdn/assets/bg2/pubg-mobile-dark-2.webp',
  CODMobile: 'https://storage.googleapis.com/lfgcdn/assets/bg3/cod.webp',
  Standoff2: 'https://storage.googleapis.com/lfgcdn/assets/bg3/standoff2.webp',
  GooseGooseDuck: 'https://storage.googleapis.com/lfgcdn/assets/bg3/ggd.webp',
  Custom: 'https://storage.googleapis.com/lfgcdn/assets/bg3/custom.webp',
  ShadowFight3:
    'https://storage.googleapis.com/lfgcdn/assets/bg3/shadow-fight-3.webp',
  ShadowFight4Arena:
    'https://storage.googleapis.com/lfgcdn/assets/bg3/shadow-fight-4.webp',
  DawnOfZombiesSurvival:
    'https://storage.googleapis.com/lfgcdn/assets/bg3/doz-survival.webp',
  Crossfire: 'https://storage.googleapis.com/lfgcdn/assets/bg3/crossout-mobile.webp',
  NewStateMobile:
    'https://storage.googleapis.com/lfgcdn/assets/bg3/new-state-mobile.webp',
  RiseOfEmpires:
    'https://storage.googleapis.com/lfgcdn/assets/bg3/rise-of-empires.webp',
  ModernStrikeOnlinePvPFPS:
    'https://storage.googleapis.com/lfgcdn/assets/bg3/modern-strike-online.webp',
  RiseOfKingdomsLostCrusade:
    'https://storage.googleapis.com/lfgcdn/assets/bg3/rise-of-kingdoms.webp',
  IdleHeroes: 'https://storage.googleapis.com/lfgcdn/assets/bg3/Idle-heroes.webp',
  LastDayOnEarthSurvival:
    'https://storage.googleapis.com/lfgcdn/assets/bg3/last-day-on-earth.webp',
  MobileLegendsAdventure:
    'https://storage.googleapis.com/lfgcdn/assets/bg3/mobile-legends-adventure.webp',
  STALKER2HeartOfChornobyl:
    'https://storage.googleapis.com/lfgcdn/assets/bg3/stalker-2.webp',
  Highrise: 'https://storage.googleapis.com/lfgcdn/assets/bg3/highrise.webp',
  AFKArena: 'https://storage.googleapis.com/lfgcdn/assets/bg3/afk-arena.webp',
};

export function getBackgroundUrl(
  game: api.Maybe<SupportedGameId>,
): string | undefined {
  if (!game) {
    return undefined;
  }

  return BACKGROUND_IMGS[game];
}

export function useGameTabs(gameId: api.Maybe<SupportedGameId>): ContentTab[] {
  const text = useText(state => state.home.navigation);
  const text2 = useText(state => state.user.navigation);

  const tabs = useMemo<ContentTab[]>(() => {
    if (!gameId) {
      return [];
    }

    const tabs: ContentTab[] = [
      {
        label: text.events,
        link: links.groups(gameId),
        onClick: () => analytics.clickGroupsTab(gameId),
      },
      {
        label: text.players,
        link: links.players(gameId),
        onClick: () => analytics.clickPlayersTab(gameId),
      },
    ];

    if (gameId === api.GameId.PubgMobile) {
      tabs.push({
        label: text2.leaderboard,
        link: links.pubgMobile.leaderboards(),
        onClick: () => analytics.clickLaddersTab(gameId),
      });
    }

    return tabs;
  }, [gameId]);

  return tabs;
}
