import React from 'react';

import css from './FixedFooter.module.css';

interface FixedFooterProps {
  children: React.ReactNode;
}

export const FixedFooter: React.FC<FixedFooterProps> = ({ children }) => {
  return <footer className={css.container}>{children}</footer>;
};
