import { GraphQLClient } from 'graphql-request';

import { AUTH_TOKEN, BACKEND_ENDPOINT } from 'utils/apollo';
import { cookie } from 'utils/main';

import { api } from './index';

const client = new GraphQLClient(BACKEND_ENDPOINT);

export async function getMe(cookieStr?: string): Promise<api.GetMeQuery['getMe']> {
  const token = cookie.get(AUTH_TOKEN, cookieStr);
  if (!token) {
    return { isAuthenticated: false };
  }

  return client
    .request<api.GetMeQuery>(api.GetMeDocument, {}, { [AUTH_TOKEN]: token })
    .then(data => data.getMe);
}

export async function getMyLfgsWithoutGames(cookieStr?: string): Promise<api.Lfg[]> {
  const token = cookie.get(AUTH_TOKEN, cookieStr);
  if (!token) {
    return [];
  }

  return client
    .request<api.GetMyLfgWithoutGamesQuery>(
      api.GetMyLfgWithoutGamesDocument,
      {},
      { [AUTH_TOKEN]: token },
    )
    .then(data => data.getMe.user?.lfg || []);
}

export async function countGroups(gameId?: api.GameId): Promise<number> {
  return client
    .request<api.SearchEventsCountQuery, api.SearchEventsCountQueryVariables>(
      api.SearchEventsCountDocument,
      { filter: { gameId }, iteratorOptions: { limit: 1 } },
    )
    .then(data => data.searchEvents.count);
}
