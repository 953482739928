import React from 'react';

import { ApolloError } from '@apollo/client';

import { Warning } from 'components/Icons/Icons';
import { Text } from 'components/Text';

import css from './ContentError.module.css';

interface ContentErrorProps {
  error: ApolloError;
}

export const ContentError = React.memo<ContentErrorProps>(({ error }) => {
  return (
    <article className={css.container}>
      <Warning className={css.errorIcon} />
      <Text.R3Memo className={css.errorText}>{error.message}</Text.R3Memo>
    </article>
  );
});
