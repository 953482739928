import React, { useEffect, useState } from 'react';

import FacebookLogin from '@greatsumini/react-facebook-login';

import cx from 'classnames';

import { api, refetchMe } from 'api';

import { useApp } from 'components/ContextApp';
import { IS_BETA } from 'const';
import { useText } from 'text';

import { cookie } from 'utils/main';

import { Button } from '../Button';
import { Icon } from '../Icons';

import { useToasts } from '../Toast';

import css from './ButtonSocial.module.css';

import { authUserByToken, ButtonSocialProps, HandlerProps } from './helpers';

type AuthFacebookProps = ButtonSocialProps & HandlerProps;

export const ButtonSocialFacebookAuth: React.FC<AuthFacebookProps> = ({
  colored = true,
  withIcon = true,
  disabled = false,
  onClick = () => undefined,
  onComplete = () => undefined,
  onError = () => undefined,
}) => {
  const app = useApp();

  const text = useText(state => state.controls.loginModal);
  const toasts = useToasts();

  const [authFacebook, { client }] = api.useAuthFacebookMutation({
    update: (_, response) =>
      authUserByToken(client, response.data?.authFacebook.token),
    onError: error => {
      toasts.showError(error, api.AuthGoogleDocument);
      onError();
    },
    refetchQueries: [refetchMe],
    onCompleted: onComplete,
  });

  return (
    <FacebookLogin
      appId="217520568848460"
      dialogParams={{
        // window is unavailable in SSR
        redirect_uri: `https://${IS_BETA ? 'beta.' : ''}lf.group/auth/facebook`,
        response_type: 'token',
        state: '',
      }}
      render={({ onClick }) => (
        <Button
          className={cx(css.buttonContainer, { [css.discordColored]: colored })}
          color="secondary"
          disabled={disabled}
          Icon={withIcon ? Icon.FacebookOutlined : undefined}
          onClick={() => {
            if (onClick) {
              // set auth redirect cookie to current page
              cookie.set('authRedirect', window.location.pathname, 1);
              onClick();
            }
          }}
        >
          {`${text.title} Facebook`}
        </Button>
      )}
      useRedirect={app.isMobile}
      onFail={error => {
        alert('Login via facebook failed!' + error);
        console.log('Login Failed!', error);
      }}
      onSuccess={async response => {
        try {
          await authFacebook({
            variables: {
              data: {
                code: response.accessToken,
              },
            },
          });
        } catch (error) {
          console.log('login error', error);
        }
        console.log('Login Success!', response);
      }}
    />
  );
};
