import { withApp } from 'components/ContextApp';

import { PageHomeFeed, getInitialProps } from 'containers/PageHomeFeed';
import { newPage } from 'utils/next';

const Page = newPage(PageHomeFeed);

// const Page = () => {
//   return (
//     <div className="shrink-0 p-20 text-3xl font-bold underline">Hello, World!</div>
//   );
// };

Page.getInitialProps = async ctx => {
  const props = await getInitialProps(ctx);

  return props;
};

export default withApp(Page, { withApollo: true });
