import { api } from 'api';
import { AuthButtonsOrder } from 'components/AuthButtons/AuthButtons';
import { Icon } from 'components/Icons';
import { useText } from 'text';

import type { SupportedGameId } from 'api/types';

export const SEARCH_EVENTS_LIMIT = 100;

export const GAME_LIST: SupportedGameId[] = [
  api.GameId.Csgo,
  api.GameId.PubgMobile,
  api.GameId.Standoff2,
  api.GameId.LeagueOfLegends,
  api.GameId.LostArkEn,
  api.GameId.WorldOfWarcraft,
  api.GameId.CodMobile,
  api.GameId.GooseGooseDuck,
  api.GameId.Hearthstone,
  api.GameId.ShadowFight3,
  api.GameId.ShadowFight4Arena,
  api.GameId.DawnOfZombiesSurvival,
  api.GameId.Crossfire,
  api.GameId.NewStateMobile,
  api.GameId.RiseOfEmpires,
  api.GameId.ModernStrikeOnlinePvPfps,
  api.GameId.RiseOfKingdomsLostCrusade,
  api.GameId.IdleHeroes,
  api.GameId.LastDayOnEarthSurvival,
  api.GameId.MobileLegendsAdventure,
  api.GameId.Stalker2HeartOfChornobyl,
  api.GameId.Highrise,
  api.GameId.AfkArena,
];

type Feature = {
  title: string;
  description: string;
  Icon: React.NamedExoticComponent<Icon.IconProps>;
};

type Features = {
  title: string;
  features: Feature[];
};

export const useGetWelcomeFeaturesByGameId = (
  gameId: api.Maybe<api.GameId>,
): Features => {
  const text = useText(state => state.welcome);

  const getFeaturesByGameKey = (gameKey: keyof typeof text.features): Features => ({
    title: text.features[gameKey].title,
    features: [
      {
        title: text.features[gameKey].point1.title,
        description: text.features[gameKey].point1.description,
        Icon: Icon.GamePads,
      },
      {
        title: text.features[gameKey].point2.title,
        description: text.features[gameKey].point2.description,
        Icon: Icon.Chats,
      },
      {
        title: text.features[gameKey].point3.title,
        description: text.features[gameKey].point3.description,
        Icon: Icon.Group,
      },
    ],
  });

  switch (gameId) {
    case api.GameId.WorldOfWarcraft:
      return getFeaturesByGameKey('wow');
    case api.GameId.LeagueOfLegends:
      return getFeaturesByGameKey('lol');
    case api.GameId.LostArkEn:
      return getFeaturesByGameKey('la');
    case api.GameId.PubgMobile:
      return getFeaturesByGameKey('pbgm');
    case api.GameId.CodMobile:
      return getFeaturesByGameKey('default');
    // case api.GameId.Hearthstone:
    // return getFeaturesByGameKey('hs');
    // case api.GameId.Csgo:
    // return getFeaturesByGameKey('csgo');

    default:
      return getFeaturesByGameKey('default');
  }
};

export const getAuthButtonsOrderByGameId = (
  gameId: api.Maybe<api.GameId>,
): AuthButtonsOrder => {
  switch (gameId) {
    case api.GameId.WorldOfWarcraft:
      return ['battlenet', 'discord', 'twitch', 'google', 'facebook'];
    case api.GameId.LeagueOfLegends:
      return ['discord', 'twitch', 'google', 'facebook'];
    case api.GameId.LostArkEn:
      return ['discord', 'twitch', 'google', 'facebook'];
    case api.GameId.PubgMobile:
      return ['discord', 'twitch', 'google', 'facebook'];
    case api.GameId.CodMobile:
      return ['discord', 'twitch', 'google', 'facebook'];
    case api.GameId.Standoff2:
      return ['discord', 'twitch', 'google', 'facebook'];
    case api.GameId.GooseGooseDuck:
      return ['discord', 'twitch', 'google', 'facebook'];
    default:
      return ['discord', 'google', 'facebook', 'battlenet', 'twitch'];
  }
};

export const useGetAuthTextByGameId = (
  gameId: api.Maybe<api.GameId>,
): string | null => {
  const text = useText(state => state.welcome);

  switch (gameId) {
    case api.GameId.WorldOfWarcraft:
      return text.auth.wow.description;
    case api.GameId.LeagueOfLegends:
      return text.auth.lol.description;
    case api.GameId.LostArkEn:
      return text.auth.la.description;
    case api.GameId.PubgMobile:
    case api.GameId.CodMobile:
    case api.GameId.Standoff2:
    case api.GameId.GooseGooseDuck:
      return text.auth.pbgm.description;
    default:
      return null;
  }
};
