import React, { useCallback } from 'react';

import { useRouter } from 'next/router';

import { api } from 'api';
import { SupportedGameId } from 'api/types';

import {
  HomeGameSelect,
  GameSelectValue,
} from 'containers/ModalDesktopEvent/EventBasicForm/EventBasicFormSelects';
import { NavigationPage } from 'layouts/LayoutApp/Navigation/helpers';
import { useText } from 'text';
import { getCurrentLocationGame, links } from 'utils/links';

import css from './Navigation.module.css';

interface NavigationProps {
  myGames: api.GameId[];
  pathname: string;

  onItemClick?: (tab: NavigationPage, event: React.MouseEvent) => void;
  onGameClick?: (
    game: SupportedGameId,
    event: React.MouseEvent,
    isMyGame: boolean,
  ) => void;
}

export const Navigation: React.FC<NavigationProps> = ({ pathname, onGameClick }) => {
  const router = useRouter();
  const text = useText(state => state.gameData);

  const activeGame = getCurrentLocationGame(pathname);

  const handleGameClick = useCallback(
    (game: api.Maybe<GameSelectValue>) => {
      if (game === activeGame) {
        return;
      }

      if (!game || game === 'Home') {
        router.push(links.home);

        return;
      }

      router.push(links.groups(game as SupportedGameId));
    },
    [onGameClick],
  );

  return (
    <nav className={css.container}>
      <HomeGameSelect
        withHome
        gridSize={4}
        label={text.event.game}
        value={activeGame || 'Home'}
        onChange={handleGameClick}
      />
    </nav>
  );
};
