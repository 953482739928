import React, { useEffect } from 'react';

import { PostEventList } from 'components/CalendarEventCard';
import { useOnboardingModal } from 'components/ContextModal/onboarding';
import { Error } from 'components/Error';

import { FollowSuggestionsBanner } from 'components/FollowSuggestionsBanner';
import { Layout } from 'components/Layout';
import { Slide } from 'containers/ModalAuthOnboarding/helpers';
import { GroupsMetaRich } from 'containers/Page[Game]GroupsFeed/GroupsMetaRich';
import { LayoutMobileGameFeed } from 'layouts/LayoutMobile[Game]Feed';

import { useText } from 'text';

import css from './Mobile.module.css';

import type { SharedProps } from './Container';

export const Mobile: React.FC<SharedProps> = ({
  isLoading = false,
  me,
  events,
  myGamesIds,
  meta,
  followSuggestions,
  isSuggestionsLoading,
  onRequestMore,
  onLogoClick,
  onRequestRefetchEvents = () => undefined,
}) => {
  const text = useText(state => state.homeFeed);

  const onboadingModal = useOnboardingModal();
  useEffect(() => {
    if (!me) {
      setTimeout(() => onboadingModal.prefetch(), 4000);
    }
  }, [me?.id]);

  const renderError = () => {
    if (me && myGamesIds?.length === 0) {
      return (
        <Error
          actionText={text.actions.addGameProfile}
          className={css.error}
          onAction={() =>
            onboadingModal.open({
              canBeClosed: true,
              slides: [Slide.GameBlocks, Slide.GameSettings],
            })
          }
        >
          {text.errors.noGames}
        </Error>
      );
    }

    if (!events?.data || events?.data?.length === 0) {
      return (
        <Error
          // actionText={text.actions.createEvent}
          className={css.error}
          // onAction={() => event.open({})}
          subtext={text.errors.nothingHappeningButWithoutGame}
        >
          {text.errors.nothingHappening}
        </Error>
      );
    }

    return (
      <FollowSuggestionsBanner
        canBeHidden
        followSuggestions={(followSuggestions?.data || []).slice(0, 10)}
        isLoading={isSuggestionsLoading}
        wrapperClassName={css.followSuggestionsBannerWrapper}
        onRequestRefetchEvents={onRequestRefetchEvents}
      />
    );
  };

  const renderContent = () => {
    if (isLoading) {
      return <Layout.Loading />;
    }

    return (
      <>
        {renderError()}
        <PostEventList
          _analyticGroup="groupFeed"
          events={events}
          isLoading={isLoading}
          renderEmpty={() => <></>}
          onRequestMore={onRequestMore}
        />
      </>
    );
  };

  return (
    <LayoutMobileGameFeed
      transparentBackground
      gameId={undefined}
      me={me}
      meta={meta}
      title={text.title}
      onLogoClick={onLogoClick}
    >
      <GroupsMetaRich groups={events?.data} />
      {renderContent()}
    </LayoutMobileGameFeed>
  );
};
