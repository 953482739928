import React from 'react';

import Head from 'next/head';
import Script from 'next/script';

import { api } from 'api';

import { getGroupMetaLdJson } from './helpers';

export interface GroupMetaRichProps {
  groups: api.Maybe<api.Event[]>;
}

export const GroupsMetaRich = React.memo<GroupMetaRichProps>(({ groups = [] }) => {
  const meta = groups.map(group => getGroupMetaLdJson(group));

  return (
    <Head>
      <>
        <Script
          dangerouslySetInnerHTML={{ __html: JSON.stringify(meta) }}
          id="group-meta-rich-groups-feed-page"
          type="application/ld+json"
        />
      </>
    </Head>
  );
});
