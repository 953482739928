import React from 'react';

import { NetworkStatus } from '@apollo/client';

import Link from 'next/link';

import { api, Iterator, useMe } from 'api';

import { PostEventList } from 'components/CalendarEventCard';
import { Error } from 'components/Error';
import { AppLayout } from 'components/Layout';

import { SITE_DOMAIN } from 'const';
import { GroupsMetaRich } from 'containers/Page[Game]GroupsFeed/GroupsMetaRich';
import { getDefaultFilters } from 'containers/Page[Game]GroupsFeed/helpers';
import { useText } from 'text';
import { links } from 'utils/links';
import { Meta } from 'utils/meta';

import { SEARCH_EVENTS_LIMIT } from './helpers';

import css from './PageUnloggedWelcome.module.css';
import { WelcomePageLayout } from './WelcomePageLayout';

import type { SupportedGameId } from 'api/types';

interface PageWelcomeGroupsProps {
  isSsr: boolean;
  currentGameId: api.Maybe<SupportedGameId>;
  meta: Meta;
}

export const PageWelcomeGroups: React.FC<PageWelcomeGroupsProps> = ({
  isSsr,
  currentGameId,

  meta,
}) => {
  const me = useMe();

  const text = useText(state => state.homeFeed);

  const renderError = () => {
    if (isLoading) {
      return null;
    }

    if (events?.data && events.data.length === 0) {
      if (!currentGameId) {
        return (
          <Error className={css.error}>
            {text.errors.nothingHappening}
            <br />
            {text.errors.nothingHappeningButWithoutGame}
          </Error>
        );
      }

      return (
        <Error className={css.error}>
          {text.errors.nothingHappening}
          <br />
          {text.errors.nothingHappeningBut}{' '}
          <Link href={links.players(currentGameId)}>
            {`${SITE_DOMAIN}${links.players(currentGameId).pathname}`}
          </Link>
        </Error>
      );
    }
  };

  const defaultFilters = getDefaultFilters({
    me,
    language: undefined,
    gameId: currentGameId as SupportedGameId,
  });

  const { data, fetchMore, error, networkStatus, loading } = api.useSearchEventsQuery(
    {
      variables: defaultFilters,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: isSsr ? 'cache-first' : 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    },
  );

  const events = data?.searchEvents as Iterator<api.Event[]>;
  const isLoading = [NetworkStatus.refetch, NetworkStatus.setVariables].includes(
    networkStatus,
  );

  return (
    <AppLayout>
      <GroupsMetaRich groups={events?.data} />
      <WelcomePageLayout gameId={currentGameId} meta={meta}>
        {renderError()}
        <PostEventList
          _analyticGroup="allTab"
          error={error}
          events={events}
          isLoading={isLoading || (loading && !isSsr && !data)}
          renderEmpty={() => <></>}
          onRequestMore={() =>
            fetchMore({
              variables: {
                filters: {},
                iteratorOptions: {
                  limit: SEARCH_EVENTS_LIMIT,
                  offset: events?.data.length || 0,
                },
              },
            })
          }
        />
      </WelcomePageLayout>
    </AppLayout>
  );
};
