import React, { useEffect } from 'react';

import { PostEventList } from 'components/CalendarEventCard';
import { useEventModal } from 'components/ContextModal/event';
import { useOnboardingModal } from 'components/ContextModal/onboarding';
import { Error } from 'components/Error';
import { FollowSuggestionsBanner } from 'components/FollowSuggestionsBanner';
import { PageWelcomeGroups } from 'components/PageUnloggedWelcome';
import { Slide } from 'containers/ModalAuthOnboarding/helpers';
import { LayoutGameFeed } from 'layouts/Layout[Game]Feed';
import { useText } from 'text';

import css from './Desktop.module.css';

import type { SharedProps } from './Container';

export const Desktop: React.FC<SharedProps> = ({
  isLoading = false,
  isSuggestionsLoading = false,
  me,
  events,
  error,
  meta,
  myGamesIds,
  followSuggestions,
  hasRenderedOnServer,
  onRequestMore,
  onLogoClick,
  onRequestRefetchEvents = () => undefined,
}) => {
  const text = useText(state => state.homeFeed);

  const onboardingModal = useOnboardingModal();
  const eventModal = useEventModal();
  useEffect(() => {
    if (!me) {
      setTimeout(() => onboardingModal.prefetch(), 4000);
    }
  }, [me?.id]);

  const renderError = () => {
    if (me && myGamesIds?.length === 0) {
      return (
        <Error
          actionText={text.actions.addGameProfile}
          className={css.error}
          onAction={() => {
            onboardingModal.open({
              canBeClosed: true,
              slides: [Slide.GameBlocks, Slide.GameSettings],
            });
          }}
        >
          {text.errors.noGames}
        </Error>
      );
    }

    if (!events?.data || events?.data?.length === 0) {
      return (
        <Error
          actionText={text.actions.createEvent}
          className={css.error}
          subtext={
            text.errors.nothingHappeningButWithoutGame +
            ' ' +
            text.errors.nothingHappeningBut2
          }
          onAction={() => eventModal.open({})}
        >
          {text.errors.nothingHappening}
        </Error>
      );
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return null;
    }

    return (
      <>
        {renderError()}

        <PostEventList
          _analyticGroup="allTab"
          events={events}
          isLoading={isLoading}
          renderEmpty={() => <></>}
          onRequestMore={onRequestMore}
        />
      </>
    );
  };

  if (!me) {
    return (
      <PageWelcomeGroups
        currentGameId={undefined}
        isSsr={hasRenderedOnServer}
        meta={meta}
      />
    );
  }

  return (
    <LayoutGameFeed
      error={error}
      gameId={undefined}
      isLoading={isLoading}
      me={me}
      meta={meta}
      renderBanners={() => (
        <FollowSuggestionsBanner
          followSuggestions={(followSuggestions?.data || []).slice(0, 10)}
          isLoading={isSuggestionsLoading}
          onRequestRefetchEvents={onRequestRefetchEvents}
        />
      )}
      title={text.title}
      onClickNavigationLogo={onLogoClick}
    >
      {renderContent()}
    </LayoutGameFeed>
  );
};
