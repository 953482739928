import React, { useMemo, useState } from 'react';

import cx from 'classnames';

import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { analytics } from 'analytics';
import { api } from 'api';
import { SupportedGameId } from 'api/types';

import { AuthButtons } from 'components/AuthButtons';
import { Button } from 'components/Button';
import { useOnboardingModal } from 'components/ContextModal/onboarding';
import { Icon } from 'components/Icons';
import { useTranslations, useText } from 'components/Language';
import { Layout } from 'components/Layout';
import { Slick } from 'components/Slick';
import { Text } from 'components/Text';
import { IS_BETA } from 'const';
import { GAME_BACKGROUND_URLS } from 'const/gameBackgrounds';
import { Slide } from 'containers/ModalAuthOnboarding/helpers';
import { ContentTab, ContentTabs } from 'layouts/LayoutColumns';
import { links } from 'utils/links';
import { getIsOnboardingCompletedKey } from 'utils/location';
import { Meta } from 'utils/meta';

import {
  GAME_LIST,
  useGetWelcomeFeaturesByGameId,
  getAuthButtonsOrderByGameId,
  useGetAuthTextByGameId,
} from './helpers';
import css from './PageUnloggedWelcome.module.css';

export type WelcomeTab = 'events' | 'players' | 'teams' | 'vacancies' | 'leaderboard';

const STEP = 500;

interface WelcomePageLayoutProps {
  children: React.ReactNode;
  gameId: api.Maybe<SupportedGameId>;
  meta?: Meta;
}

const getCurrentTab = (route: string): WelcomeTab | undefined => {
  if (route.includes('/events')) {
    return 'events';
  }
  if (route.includes('/players')) {
    return 'players';
  }
  if (route.includes('/teams')) {
    return 'teams';
  }
  if (route.includes('/vacancies')) {
    return 'vacancies';
  }

  return 'events';
};

export const WelcomePageLayout: React.FC<WelcomePageLayoutProps> = ({
  children,
  gameId,
  meta,
}) => {
  const Meta = useMemo(() => {
    const devQuery = IS_BETA ? '?dev=true' : '';

    return (
      <Head>
        {meta?.title || meta?.og?.title ? (
          <title>{meta.title || meta?.og?.title}</title>
        ) : null}
        {meta?.og?.description ? (
          <meta content={meta.og.description} name="description" />
        ) : null}
        {meta?.og?.title ? (
          <meta content={meta.og.title} property="og:title" />
        ) : null}
        {meta?.og?.description ? (
          <meta content={meta.og.description} property="og:description" />
        ) : null}
        {meta?.og?.image ? (
          <meta content={meta.og.image + devQuery} property="og:image" />
        ) : null}
        {meta?.og?.image ? (
          <meta content="image/png" property="og:image:type" />
        ) : null}
        {meta?.og?.image ? (
          <meta content="summary_large_image" name="twitter:card" />
        ) : null}
        {meta?.og?.image ? (
          <meta content={meta.og.image} name="twitter:image" />
        ) : null}
      </Head>
    );
  }, [
    meta?.title,
    meta?.description,
    meta?.og?.title,
    meta?.og?.description,
    meta?.og?.image,
  ]);

  const router = useRouter();
  const text = useText(state => state.welcome);
  const text2 = useText(state => state.user.navigation);

  const onboardingModal = useOnboardingModal();

  const translations = useTranslations();
  const authDescription = useGetAuthTextByGameId(gameId);

  const currentTab: WelcomeTab = getCurrentTab(router.route) || 'events';
  const [isAuthLoading, setIsAuthLoading] = useState<boolean>(false);
  const [tab] = useState<WelcomeTab>(currentTab);

  const pageTitle = useMemo(() => {
    if (!gameId) {
      return text.pageTitle;
    }
    const gameName = translations.gameTitle(gameId).short;

    switch (currentTab) {
      case 'events':
        return `${gameName} ${text.header.events}`;
      case 'players':
        return `${gameName} ${text.header.players}`;
      case 'teams':
        return `${gameName} ${text.header.teams}`;
      case 'vacancies':
        return `${gameName} ${text.header.vacancies}`;
    }
  }, [gameId, currentTab]);

  const pageDescription = !gameId && text.pageDescription;

  const playersLink = useMemo(
    () => (gameId ? links.players(gameId) : links.home),
    [gameId],
  );
  const leaderboardLink = useMemo(() => links.pubgMobile.leaderboards(), []);

  const groupsLink = useMemo(() => {
    if (!gameId) {
      return links.home;
    }

    return links.groups(gameId);
  }, [gameId]);

  return (
    <>
      {Meta}
      {/* <WelcomeHeader gameId={gameId} /> */}
      <Layout.Wrapper>
        <Layout.Content
          isHeader={false}
          routeName=""
          style={{ maxWidth: 'var(--max-welcome-content-width)' }}
        >
          <div className={css.titleWithDescription}>
            <Text.H1 semibold className={css.title}>
              {pageTitle}
            </Text.H1>
            {pageDescription ? (
              <Text.H1 className={css.description}>{pageDescription}</Text.H1>
            ) : null}
          </div>
          {!gameId && (
            <Slick step={STEP}>
              {GAME_LIST.map((gameId, key) => {
                return <GameCard key={key} gameId={gameId as SupportedGameId} />;
              })}
            </Slick>
          )}

          <div className={css.mainSection}>
            <div className={css.mainSectionContent}>
              <div className={css.content}>
                {Boolean(gameId) ? (
                  <div className={css.tabsWrapper}>
                    <ContentTabs
                      activeTab={(() => {
                        switch (tab) {
                          case 'events':
                            return groupsLink.pathname;
                          case 'players':
                            return playersLink.pathname;
                          case 'leaderboard':
                            return leaderboardLink.pathname;
                        }
                      })()}
                      tabs={[
                        {
                          label: text.tabs.events,
                          link: groupsLink,
                        },
                        {
                          label: text.tabs.players,
                          link: playersLink,
                        },
                        ...([
                          gameId === api.GameId.PubgMobile
                            ? { label: text2.leaderboard, link: leaderboardLink }
                            : null,
                        ].filter(Boolean) as ContentTab[]),
                      ]}
                    />
                    {gameId === api.GameId.LeagueOfLegends && (
                      <div className={css.externalLinkContainer}>
                        <a
                          href="https://youtu.be/eWbB3kD--k8"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <Text.Regular
                            className={cx(css.createGroupLink, css.tabMerchant)}
                          >
                            {text.sections.howToCreateGroup}
                          </Text.Regular>
                        </a>
                      </div>
                    )}
                  </div>
                ) : (
                  <section>
                    <Text.H2 style={{ whiteSpace: 'pre', marginTop: 16 }}>
                      {text.noGameDescription}
                    </Text.H2>
                  </section>
                )}

                <div className={css.innerContent}>{children}</div>
              </div>
              <div className={css.rightContent}>
                <div className={css.banners}>
                  <LfgAdvantagesBanner gameId={gameId} />
                  <div className={css.authButtonsWrapper}>
                    {gameId ? (
                      <Text.R1 semibold className={css.advantagesHeader}>
                        {text.auth.title}
                      </Text.R1>
                    ) : null}
                    {authDescription ? (
                      <Text.R3 className={css.authDescription}>
                        {authDescription}
                      </Text.R3>
                    ) : null}
                    <AuthButtons
                      authButtonsOrder={getAuthButtonsOrderByGameId(gameId)}
                      handleAuthComplete={vendor => {
                        analytics.loginCompleteSuccess(vendor);
                        setIsAuthLoading(false);

                        const isGameCompletedKey =
                          getIsOnboardingCompletedKey(gameId);
                        const isGameCompleted =
                          localStorage.getItem(isGameCompletedKey);

                        if (isGameCompleted) {
                          return;
                        }

                        onboardingModal.open({
                          // initialGame: gameId,
                          slides: [
                            Slide.GameBlocks,
                            Slide.StreamerSuggestions,
                            Slide.GameSettings,
                          ],
                        });
                      }}
                      handleAuthError={vendor => {
                        analytics.loginCompleteError(vendor);
                        setIsAuthLoading(false);
                      }}
                      handleClickAuth={vendor => {
                        if (vendor === 'battlenet') {
                          return;
                        }
                        setIsAuthLoading(true);
                      }}
                      isLoading={isAuthLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout.Content>
      </Layout.Wrapper>
    </>
  );
};

interface WelcomeHeaderProps {
  gameId: api.Maybe<SupportedGameId>;
}

export const WelcomeHeader: React.FC<WelcomeHeaderProps> = ({}) => {
  const authModalText = useText(state => state.controls.loginModal);
  const authOnboardingText = useText(
    state => state.modalAuthOnboarding.loginScenario,
  );

  const onboardingModal = useOnboardingModal();

  return (
    <header className={css.header}>
      <Button
        className={css.authButton}
        onClick={() => {
          analytics.clickLogin();
          onboardingModal.open({
            title: authOnboardingText.desc,
            // initialGame: gameId,
          });
        }}
      >
        {`${authModalText.action}`}
      </Button>
      <Button
        className={css.authButton}
        onClick={() => {
          analytics.clickLogin();
          onboardingModal.open({
            title: authOnboardingText.desc,
            // initialGame: gameId,
          });
        }}
      >
        {`${authModalText.signUp}`}
      </Button>
    </header>
  );
};

interface GameCardProps {
  gameId: SupportedGameId;

  isSoon?: boolean;
}

const groupsAndPlayersCountByGameId: {
  [key in SupportedGameId]: { groups: string; players: string };
} = {
  [api.GameId.WorldOfWarcraft]: {
    groups: '434',
    players: '12k+',
  },
  [api.GameId.LostArkEn]: {
    groups: '567',
    players: '25.5k+',
  },
  [api.GameId.LeagueOfLegends]: {
    groups: '152',
    players: '12k+',
  },
  [api.GameId.Custom]: {
    groups: '870',
    players: '328',
  },
  [api.GameId.PubgMobile]: {
    groups: '221',
    players: '120к+',
  },
  [api.GameId.CodMobile]: {
    groups: '176',
    players: '400+',
  },
  [api.GameId.Standoff2]: {
    groups: '153',
    players: '14k+',
  },
  [api.GameId.GooseGooseDuck]: {
    groups: '65',
    players: '35+',
  },
  [api.GameId.Csgo]: {
    groups: '450',
    players: '2.6к+',
  },
  [api.GameId.Hearthstone]: {
    groups: '733',
    players: '230+',
  },
  [api.GameId.ShadowFight3]: {
    groups: '1.2k',
    players: '230',
  },
  [api.GameId.ShadowFight4Arena]: {
    groups: '1.2k',
    players: '230',
  },
  [api.GameId.DawnOfZombiesSurvival]: {
    groups: '1.2k',
    players: '230',
  },
  [api.GameId.Crossfire]: {
    groups: '1.2k',
    players: '230',
  },
  [api.GameId.NewStateMobile]: {
    groups: '1.2k',
    players: '230',
  },
  [api.GameId.RiseOfEmpires]: {
    groups: '1.2k',
    players: '230',
  },
  [api.GameId.ModernStrikeOnlinePvPfps]: {
    groups: '1.2k',
    players: '230',
  },
  [api.GameId.RiseOfKingdomsLostCrusade]: {
    groups: '1.2k',
    players: '230',
  },
  [api.GameId.IdleHeroes]: {
    groups: '1.2k',
    players: '230',
  },
  [api.GameId.LastDayOnEarthSurvival]: {
    groups: '1.2k',
    players: '230',
  },
  [api.GameId.MobileLegendsAdventure]: {
    groups: '1.2k',
    players: '230',
  },
  [api.GameId.Stalker2HeartOfChornobyl]: {
    groups: '1.2k',
    players: '230',
  },
  [api.GameId.Highrise]: {
    groups: '1.2k',
    players: '230',
  },
  [api.GameId.AfkArena]: {
    groups: '1.2k',
    players: '230',
  },
};

const GameCard: React.FC<GameCardProps> = ({ gameId, isSoon }) => {
  const text = useTranslations();
  const welcomeText = useText(state => state.welcome);
  const backgroundImage = GAME_BACKGROUND_URLS[gameId];

  const { players } = groupsAndPlayersCountByGameId[gameId];

  return (
    <div key={gameId} className={css.gameCard}>
      <div
        className={css.gameCardImage}
        style={{ backgroundImage: `url("${backgroundImage}")` }}
      />
      <div className={css.gameCardImageShadow} />
      <div className={css.gameCardShadow} />
      {isSoon ? <Soon /> : null}
      <div className={css.gameCardContent}>
        <Link href={links.groups(gameId)}>
          <Text.Regular className={css.gameCardTitle} size={1}>
            {text.gameTitle(gameId).full}
          </Text.Regular>
        </Link>
        <Text.Regular className={css.groupsCount} size={3}>
          {welcomeText.sections.players(players)}
        </Text.Regular>
        <Link className={css.gameCardButton} href={links.groups(gameId)}>
          <Icon.SecondaryArrowRight />
        </Link>
      </div>
    </div>
  );
};

export const Soon = () => {
  const welcomeText = useText(state => state.welcome);

  return (
    <Text.R4 semibold className={css.soon}>
      {welcomeText.sections.soon}
    </Text.R4>
  );
};

const LfgAdvantagesBanner: React.FC<WelcomeHeaderProps> = ({ gameId }) => {
  const { title, features } = useGetWelcomeFeaturesByGameId(gameId);

  return (
    <div className={css.lfgAdvantages}>
      <Text.R1 semibold className={css.advantagesHeader}>
        {title}
      </Text.R1>
      {features.map((feature, index) => (
        <div key={index} className={css.advantage}>
          <div className={css.advantageHeader}>
            <feature.Icon className={css.advantageIcon} />
            <Text.R3 semibold className={css.advantageTitle}>
              {feature.title}
            </Text.R3>
          </div>
          <Text.R3 className={css.advantageDescription}>
            {feature.description}
          </Text.R3>
        </div>
      ))}
    </div>
  );
};
