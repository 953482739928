import React from 'react';

import cx from 'classnames';

import { api, refetchMe } from 'api';

import { utils } from 'utils';

import { Button } from '../Button';
import { Icon } from '../Icons';
import { useText } from '../Language';

import { useToasts } from '../Toast';

import css from './ButtonSocial.module.css';
import { authUserByToken, ButtonSocialProps, HandlerProps } from './helpers';

type TwitchProps = ButtonSocialProps & HandlerProps;

export const ButtonSocialTwitchAuth: React.FC<TwitchProps> = ({
  colored = true,
  withIcon = true,
  disabled = false,
  onClick = () => undefined,
  onComplete = () => undefined,
  onError = () => undefined,
}) => {
  const text = useText(state => state.controls.loginModal);
  const toasts = useToasts();

  const [authTwitch, { client }] = api.useAuthTwitchMutation({
    update: (_, response) => authUserByToken(client, response.data?.authTwitch.token),
    onError: error => {
      toasts.showError(error, api.AuthTwitchDocument);
      onError();
    },
    refetchQueries: [refetchMe],
    onCompleted: onComplete,
  });

  return (
    <Button
      className={cx(css.buttonContainer, { [css.twitchColored]: colored })}
      color="secondary"
      disabled={disabled}
      Icon={withIcon ? Icon.TwitchRounded : undefined}
      onClick={async event => {
        await onClick(event);
        if (event.defaultPrevented) {
          return; // Interrupted with event.preventDefault()
        }

        try {
          const authData = await utils.getTwitchAuthData();

          await authTwitch({ variables: { data: authData } });
        } catch (error) {
          onError();
        }
        utils.authStorage.twitch.resetSession();
      }}
    >
      {`${text.title} Twitch`}
    </Button>
  );
};

export const ButtonSocialTwitchConnect: React.FC<TwitchProps> = ({
  colored = true,
  withIcon = true,
  disabled = false,
  onClick = () => undefined,
  onComplete = () => undefined,
  onError = () => undefined,
}) => {
  const text = useText(state => state.controls.loginModal);
  const toasts = useToasts();

  const [connectTwitch] = api.useConnectTwitchMutation({
    onError: error => {
      toasts.showError(error, api.ConnectTwitchDocument);
      onError();
    },
    refetchQueries: [refetchMe],
    onCompleted: onComplete,
  });

  return (
    <Button
      className={cx(css.buttonContainer, { [css.twitchColored]: colored })}
      color="secondary"
      disabled={disabled}
      Icon={withIcon ? Icon.TwitchRounded : undefined}
      onClick={async event => {
        await onClick(event);
        if (event.defaultPrevented) {
          return; // Interrupted with event.preventDefault()
        }

        try {
          const authData = await utils.getTwitchAuthData();

          await connectTwitch({ variables: { data: authData } });
        } catch (error) {
          onError();
        }
        utils.authStorage.twitch.resetSession();
      }}
    >
      {`${text.title} Twitch`}
    </Button>
  );
};
