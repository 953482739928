import { useMemo } from 'react';

import { api } from 'api';
import { getMe } from 'api/request';

// import {
//   getPreferredLanguageFromCookie,
//   getPreferredLanguageFromCtxOrCookie,
//   toUserLanguage,
// } from 'utils/language';
import { useTranslations } from 'components/Language';
import { useText } from 'text';
import { filterLfgByGame } from 'utils/filterGame';

import { ClientLanguage } from 'utils/language';
import { getGameSlugPath } from 'utils/location';
import { getMeFromApolloFromClientCtx } from 'utils/next';

import { GROUPS_FEED_QUERY_CODER } from './query';

import type { PageGroupsFeedProps } from './Container';
import type { SupportedGameId } from 'api/types';
import type { NextPageContext } from 'next';

export const GROUPS_LIMIT = 100;

export const SESSION_INDEX_QUERY_KEY = (gameId: api.Maybe<SupportedGameId>) =>
  `lfg/query-index/${gameId}`;

export async function getInitialProps(
  gameId: SupportedGameId,
  ctx: NextPageContext,
): Promise<PageGroupsFeedProps> {
  const get = getInitialFilters(gameId);

  const me = getMeFromApolloFromClientCtx(ctx);

  const initialFilters =
    typeof window === 'undefined'
      ? await get.server(ctx)
      : get.client(me?.user as api.User);

  return {
    gameId: gameId,
    gameMode: undefined,
    initialFilters,
  };
}

export function getInitialFilters(gameId: SupportedGameId) {
  return {
    // On server just deserialize query & try to apply user-fitting filters, such as language
    async server(ctx: NextPageContext): Promise<api.SearchEventsQueryVariables> {
      const me = await getMe(ctx.req?.headers.cookie);
      // const language = getPreferredLanguageFromCtxOrCookie(ctx);

      const filters = GROUPS_FEED_QUERY_CODER.decode(ctx.query, {
        // Append to user default filters
        defaultValue: getDefaultFilters({
          me: me.user as api.User,
          // language: toUserLanguage(language),
          language: undefined, // #temp disable language initial filter
          gameId,
        }),
      });

      return filters;
    },

    // On client deserialize query from SessionStorage and try to apply user-fitting filters
    client(me: api.Maybe<api.User>): api.SearchEventsQueryVariables {
      const getQueryStr = (): string => {
        if (typeof sessionStorage !== 'undefined') {
          const queryStr =
            sessionStorage.getItem(SESSION_INDEX_QUERY_KEY(gameId)) || '';

          return queryStr;
        }

        return '';
      };
      const queryStr = getQueryStr();

      if (queryStr) {
        const filters = GROUPS_FEED_QUERY_CODER.decode(queryStr, {
          defaultValue: getDefaultFilters({
            gameId,
            // do not override serialized values
            me: undefined,
            language: undefined,
          }),
        });

        return filters;
      }

      // const language = getPreferredLanguageFromCookie();

      // Only user's default
      const defaultFiltes = getDefaultFilters({
        me,
        gameId,
        // language: toUserLanguage(language),
        language: undefined,
      });
      const filters = GROUPS_FEED_QUERY_CODER.decode(queryStr, {
        defaultValue: defaultFiltes,
      });

      // console.log('defaultFiltes', defaultFiltes);
      // console.log('filters', filters);
      // console.log('me', me);

      return filters;
    },
  };
}

const getMyWowRegion = (me: api.Maybe<api.User>): api.WowRegion | undefined => {
  if (!me?.battlenet) {
    return undefined;
  }

  switch (me.battlenet.region) {
    case api.BattlenetRegion.Us:
      return api.WowRegion.Us;
    case api.BattlenetRegion.Europe:
      return api.WowRegion.Europe;
    case api.BattlenetRegion.Apac:
      return api.WowRegion.China;
  }
};

interface GetDefaultFiltersArgs {
  me: api.Maybe<api.User>;
  gameId: api.Maybe<api.GameId>;
  language: api.Maybe<api.Language>;
  from?: api.Maybe<any>;
}

// TODO: move to server?
export function getDefaultFilters({
  me,
  language,
  gameId,
  from,
}: GetDefaultFiltersArgs): api.SearchEventsQueryVariables {
  const initialFrom = new Date();
  initialFrom.setHours(0, 0, 0, 0);

  const vars: api.SearchEventsQueryVariables = {
    iteratorOptions: { limit: GROUPS_LIMIT, offset: 0 },
    filter: {
      language,
      gameId,
      platform: api.Platform.Web,
      from: from || initialFrom.toISOString(),
    },
  };

  if (!me) {
    return vars;
  }

  // Apply user specific filters

  if (gameId === api.GameId.WorldOfWarcraft) {
    const wowRegion = getMyWowRegion(me);

    if (wowRegion) {
      vars.filter.wow = { region: wowRegion };
    }
  }

  if (gameId === api.GameId.LostArkEn) {
    const profile = filterLfgByGame(api.GameId.LostArkEn, me?.lfg);
    if (profile?.lostArk?.region?.length) {
      vars.filter.lostArk = { region: profile.lostArk.region[0] as any };
    }
  }

  if (gameId === api.GameId.LeagueOfLegends) {
    const profile = filterLfgByGame(api.GameId.LeagueOfLegends, me?.lfg);
    if (profile) {
      vars.filter.lol = {};

      if (profile.lol?.region) {
        vars.filter.lol.region = profile.lol.region;
      }
    }
  }
  if (gameId) {
    const profile = filterLfgByGame(gameId as SupportedGameId, me?.lfg);
    if (profile) {
      if (profile.region) {
        vars.filter.region = profile.region;
      }
    }
  }

  return vars;
}

interface PageTitles {
  title: string;
  metaTitle: string;
  metaDescription: string;
}

export function useMetaInfo(
  gameId: api.Maybe<SupportedGameId>,
  gameMode: api.Maybe<api.GameMode>,
): PageTitles {
  const text = useText(state => state.home.meta);
  const translations = useTranslations();

  /**
   * 3 cases:
   * 1. no game, no mode
   * 2. game, no mode
   * 3. game + mode
   */
  if (!gameId && !gameMode) {
    return {
      title: text.routeTitle,
      metaTitle: text.title,
      metaDescription: text.description,
    };
  }

  if (!gameId) {
    throw new Error('no game_id, but game_mode passed');
  }
  const game = translations.gameTitle(gameId);

  return {
    title: `${game.short} Events`,
    metaTitle: `${game.short} Events - Looking for Group in ${game.full} | LF.Group`,
    metaDescription: `Find new teammates and plan activities together in ${game.short}. Search for players who match your in-game goals, skill level and available time to play. Website + Discord bot. Try it out!`,
  };
}

export function useUnloggedMetaInfo(
  gameId: api.Maybe<SupportedGameId>,
  page: 'events' | 'players' | 'teams' | 'vacancies',
): PageTitles {
  const text = useText(state => state.welcome.meta);
  const translations = useTranslations();

  const pageTitle = useMemo(() => {
    switch (page) {
      case 'events':
        return 'LFG';
      case 'players':
        return 'Players';
      case 'teams':
        return 'Teams';
      case 'vacancies':
        return 'Vacancies';
    }
  }, [page]);

  /**
   * 3 cases:
   * 1. no game, no mode
   * 2. game, no mode
   * 3. game + mode
   */
  if (!gameId) {
    return {
      title: '',
      metaTitle: text.title,
      metaDescription: text.description,
    };
  }

  if (!gameId) {
    throw new Error('no game_id, but game_mode passed');
  }
  const game = translations.gameTitle(gameId);

  switch (gameId) {
    case api.GameId.WorldOfWarcraft:
      return {
        title: game.full,
        metaTitle: text.wow.title(pageTitle),
        metaDescription: text.wow.description,
      };
    case api.GameId.LeagueOfLegends:
      return {
        title: game.full,
        metaTitle: text.lol.title(pageTitle),
        metaDescription: text.lol.description,
      };
    case api.GameId.LostArkEn:
      return {
        title: game.full,
        metaTitle: text.la.title(pageTitle),
        metaDescription: text.la.description,
      };
    case api.GameId.PubgMobile:
      return {
        title: game.full,
        metaTitle: text.pbgm.title(pageTitle),
        metaDescription: text.pbgm.description,
      };
    case api.GameId.CodMobile:
      return {
        title: game.full,
        metaTitle: text.cod.title(pageTitle),
        metaDescription: text.cod.description,
      };
    // case api.GameId.Hearthstone:
    //   return {
    //     title: game.full,
    //     metaTitle: text.hs.title(pageTitle),
    //     metaDescription: text.hs.description,
    //   };
    // case api.GameId.Csgo:
    //   return {
    //     title: game.full,
    //     metaTitle: text.csgo.title(pageTitle),
    //     metaDescription: text.csgo.description,
    //   };

    default:
      return {
        title: game.full,
        metaTitle: text.title,
        metaDescription: text.description,
      };
  }
}

export function getPreviewImageUrl(
  gameId: api.Maybe<SupportedGameId>,
  locale: ClientLanguage = ClientLanguage.English,
) {
  const postfix = locale === ClientLanguage.Russian ? '/ru' : '/en';
  if (!gameId) {
    return 'https://preview.lf.group/home' + postfix;
  }

  // const path = getGameSlugPath(gameId);

  return 'https://preview.lf.group/home' + postfix;
}
