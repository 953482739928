import { ApolloClient } from '@apollo/client';

import { api } from 'api';

import { setApolloToken } from 'utils/apollo';

export const WAIT_FOR_GAMES_MS = 10 * 1000;

// You need to store token before refetchQueries, so modify request
export async function authUserByToken(
  client: ApolloClient<any>,
  token: api.Maybe<string>,
): Promise<void> {
  if (!token) {
    return;
  }
  await setApolloToken(client, token);
}

export interface HandlerProps {
  onComplete?: () => Promise<void> | void;
  refetchGames?: false | undefined | null;
}

export type HandlerWithGamesProps =
  | {
      refetchGames: true;
      loadingDelay?: number;
      onComplete?: (promisedGames: Promise<api.Maybe<api.Game[]>>) => void;
    }
  | HandlerProps;

export interface ButtonSocialProps {
  children?: string;
  withIcon?: boolean;
  colored?: boolean;
  disabled?: boolean;
  isReconnection?: boolean;
  label?: string;
  small?: boolean;

  onClick?: (event: React.MouseEvent) => void | Promise<void>;
  onError?: () => Promise<void> | void;
}
