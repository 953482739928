import React from 'react';

import { api } from 'api';

import { utils } from 'utils';

import { Button } from '../Button';
import { useText } from '../Language';

import { useToasts } from '../Toast';

import css from './ButtonSocial.module.css';
import { HandlerWithGamesProps, WAIT_FOR_GAMES_MS } from './helpers';

interface RiotCommonProps {
  label?: string;
  onClick?: (event: React.MouseEvent) => unknown | Promise<unknown>;
  onError?: () => unknown | Promise<unknown>;
  onCancel?: () => void;
  disabled?: boolean;

  modifyAuthData?: (data: api.OAuthData) => api.OAuthData;
}
type RiotProps = RiotCommonProps & HandlerWithGamesProps;

export const ButtonSocialRiotConnect: React.FC<RiotProps> = ({
  disabled = false,
  label,
  onClick = () => undefined,
  onError = () => undefined,
  onCancel = () => undefined,
  modifyAuthData = data => data,
  ...props
}) => {
  const text = useText(state => state.controls.loginModal);
  const toasts = useToasts();

  const [getMyGames] = api.useGetMyGamesLazyQuery();

  const [connectRiot] = api.useConnectRiotMutation({
    onError: error => {
      toasts.showError(error, api.ConnectRiotDocument);
      onError();
    },
    onCompleted: () => {
      if (!props.onComplete) {
        return;
      }
      if (props.refetchGames) {
        const promisedGames = utils
          .delay(props.loadingDelay || WAIT_FOR_GAMES_MS)
          .then(() => getMyGames())
          .then(data => data.data?.getMyGames as api.Maybe<api.Game[]>);

        return props.onComplete(promisedGames);
      }

      props.onComplete();
    },
  });

  return (
    <Button
      className={css.buttonRiotContainer}
      disabled={disabled}
      onClick={async event => {
        await onClick(event);
        if (event.defaultPrevented) {
          return; // Interrupted with event.preventDefault()
        }
        try {
          const result = await utils.getRiotAuthData();
          console.info('Oauth data', result);
          const data = modifyAuthData(result);

          await connectRiot({ variables: { data } });
        } catch (error) {
          onCancel();
        }
      }}
    >
      {label || `${text.title} Riot`}
    </Button>
  );
};
