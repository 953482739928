import React from 'react';

import { NetworkStatus } from '@apollo/client';

import { analytics } from 'analytics';
import { api, Iterator } from 'api';

import { GameProfileCardList } from 'components/[Game]ProfileCard';
import { Button } from 'components/Button';
import { useOnboardingModal } from 'components/ContextModal/onboarding';
import { Error } from 'components/Error';
import { AppLayout } from 'components/Layout';

import { ButtonFollowUser } from 'components/UserCard';

import { useText } from 'text';
import { Meta } from 'utils/meta';

import { WelcomePageLayout } from './WelcomePageLayout';

import type { SupportedGameId } from 'api/types';

interface PageWelcomePlayersProps {
  meta: Meta;
  isSsr: boolean;
  currentGameId: api.Maybe<SupportedGameId>;
}

export const PageWelcomePlayers: React.FC<PageWelcomePlayersProps> = ({
  meta,
  isSsr,
  currentGameId,
}) => {
  const textActions = useText(state => state.userGames.card);

  const limit = 25;
  const onboardingModal = useOnboardingModal();

  const { data, networkStatus, fetchMore, error, loading } =
    api.useSearchPlayersQuery({
      variables: {
        filter: { gameId: currentGameId },
        iteratorOptions: { limit, offset: 0 },
      },
      fetchPolicy: isSsr ? 'cache-first' : 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
    });

  const users = data?.searchPlayers as Iterator<api.User[]>;
  const isLoading = [NetworkStatus.loading, NetworkStatus.refetch].includes(
    networkStatus,
  );

  return (
    <AppLayout>
      <WelcomePageLayout gameId={currentGameId} meta={meta}>
        <GameProfileCardList
          error={error}
          isLoading={isLoading || (loading && !isSsr && !data)}
          renderActions={user => {
            return (
              <>
                <Button
                  color="dark-12"
                  onClick={() => onboardingModal.open({})}
                  onMouseOver={() => onboardingModal.prefetch()}
                >
                  {textActions.write}
                </Button>
                <ButtonFollowUser playerEventCategory="allTab" user={user} />
              </>
            );
          }}
          renderEmpty={<Error>not found</Error>}
          users={users}
          onCopyDiscordClick={user =>
            analytics.clickCopyDiscord('playersPage', currentGameId, user.id)
          }
          onRequestMore={() =>
            fetchMore({
              variables: {
                filters: {},
                iteratorOptions: { limit, offset: users?.data.length || 0 },
              },
            })
          }
        />
      </WelcomePageLayout>
    </AppLayout>
  );
};
