import { api } from 'api';
import { GROUPS_FEED_QUERY_CODER } from 'containers/Page[Game]GroupsFeed/query';
import { COOKIE_REFERRAL_CODE_KEY } from 'utils/authStorage';
import { cookie } from 'utils/main';

import type { PageHomeFeedProps } from './Container';
import type { NextPageContext } from 'next';

export const EVENTS_SEARCH_LIMIT = 100;

export function setReferralCodeToCookie(referralCode: string) {
  return cookie.set(COOKIE_REFERRAL_CODE_KEY, referralCode);
}

export const GROUPS_LIMIT = 100;

export async function getInitialProps(
  ctx: NextPageContext,
): Promise<PageHomeFeedProps> {
  const get = getInitialFilters();

  const initialFilters =
    typeof window === 'undefined' ? await get.server(ctx) : get.client();

  return {
    initialFilters,
  };
}

export function getInitialFilters() {
  return {
    // On server just deserialize query & try to apply user-fitting filters, such as language
    async server(ctx: NextPageContext): Promise<api.SearchEventsQueryVariables> {
      // const language = getPreferredLanguageFromCtxOrCookie(ctx);

      const filters = GROUPS_FEED_QUERY_CODER.decode(ctx.query, {
        // Append to user default filters
        defaultValue: getDefaultFilters({
          // language: toUserLanguage(language),
          language: undefined, // #temp disable language initial filter
        }),
      });

      return filters;
    },

    // On client deserialize query from SessionStorage and try to apply user-fitting filters
    client(): api.SearchEventsQueryVariables {
      // Only user's default
      const defaultFiltes = getDefaultFilters({
        // language: toUserLanguage(language),
        language: undefined,
      });

      return defaultFiltes;
    },
  };
}

interface GetDefaultFiltersArgs {
  language: api.Maybe<api.Language>;
  from?: api.Maybe<any>;
}

// TODO: move to server?
export function getDefaultFilters({
  language,
  from,
}: GetDefaultFiltersArgs): api.SearchEventsQueryVariables {
  const initialFrom = new Date();
  initialFrom.setHours(0, 0, 0, 0);

  const vars: api.SearchEventsQueryVariables = {
    iteratorOptions: { limit: GROUPS_LIMIT, offset: 0 },
    filter: {
      language,
      platform: api.Platform.Web,
      from: from || initialFrom.toISOString(),
    },
  };

  return vars;
}
