import React, { useCallback, useMemo } from 'react';

import { ApolloError, NetworkStatus } from '@apollo/client';
import { NextRouter, useRouter } from 'next/router';

import { analytics } from 'analytics';
import { api, Iterator, useGetMeState, useMyGames, useMyLfgs } from 'api';
import { useApp } from 'components/ContextApp';
import { getPreviewImageUrl } from 'containers/Page[Game]GroupsFeed/helpers';
import { useText } from 'text';
import { links } from 'utils/links';
import { Meta } from 'utils/meta';
import { ContainerPage } from 'utils/next';

import { Desktop } from './Desktop';
import { EVENTS_SEARCH_LIMIT } from './helpers';
import { Mobile } from './Mobile';

export interface PageHomeFeedProps {
  initialFilters: api.SearchEventsQueryVariables;
}

export interface SharedProps {
  me: api.Maybe<api.User>;
  games: api.Maybe<api.Game[]>;
  myGamesIds: api.Maybe<api.GameId[]>;
  hasRenderedOnServer: boolean;
  events: Iterator<api.Event[]>;
  meta: Meta;
  isLoading?: boolean;
  error?: api.Maybe<ApolloError>;

  router: NextRouter;
  followSuggestions: Iterator<api.User[]>;
  isSuggestionsLoading: boolean;

  onRequestConnectGames?: () => void;
  onRequestMore: () => Promise<void> | void;
  onRequestWriteMessage?: (user: api.User) => Promise<unknown> | unknown;
  onLogoClick?: (event: React.MouseEvent) => void;
  onClickActionLink?: (calendarEvent: api.Event) => void;
  onRequestRefetchEvents?: () => void | Promise<void>;
}

export const PageHomeFeed: ContainerPage<PageHomeFeedProps> = ({
  initialFilters,
  hasRenderedOnServer,
  hydratedFetchPolicy,
  isMobile,
}) => {
  const { me } = useGetMeState();
  const app = useApp();
  const text = useText(state => state.homeFeed);

  const router = useRouter();
  const games = useMyGames({ skip: !me });
  const lfgs = useMyLfgs({ skip: !me });

  const meta = useMemo<Meta>(() => {
    return {
      title: text.meta.title,
      og: {
        title: text.meta.og.title,
        description: text.meta.og.description,
        image: getPreviewImageUrl(undefined, app.language),
      },
    };
  }, []);

  const { data: followSuggestionsData, loading: followSuggestionsLoading } =
    api.useGetFollowSuggestionsQuery({
      variables: { filter: {}, options: { limit: 10 } },
      fetchPolicy: hydratedFetchPolicy,
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
      skip: !me,
    });

  const followSuggestions = followSuggestionsData?.getFollowSuggestions as Iterator<
    api.User[]
  >;

  const { data, fetchMore, refetch, error, networkStatus } = api.useSearchEventsQuery(
    {
      variables: initialFilters,
      fetchPolicy: hydratedFetchPolicy,
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
    },
  );

  const events = data?.searchEvents as Iterator<api.Event[]>;

  const isLoading =
    hydratedFetchPolicy !== 'cache-first' &&
    !events?.data &&
    [
      NetworkStatus.loading,
      NetworkStatus.refetch,
      NetworkStatus.setVariables,
    ].includes(networkStatus);

  const myGamesIds = useMemo(() => {
    return lfgs?.map(lfg => lfg.gameId);
  }, [lfgs]);

  const handleRequestMore = useCallback(async () => {
    await fetchMore({
      variables: {
        iteratorOptions: {
          limit: EVENTS_SEARCH_LIMIT,
          offset: events?.data?.length || 0,
        },
      },
    });
  }, [events?.data?.length]);

  const handleLogoClick = useCallback(() => {
    return refetch({ iteratorOptions: { limit: EVENTS_SEARCH_LIMIT } });
  }, []);

  const handleRequestRefetchEvents = useCallback(async () => {
    await refetch({ filter: initialFilters.filter });
  }, []);

  if (isMobile) {
    return (
      <Mobile
        error={error}
        events={events}
        followSuggestions={followSuggestions}
        games={games}
        hasRenderedOnServer={hasRenderedOnServer}
        isLoading={isLoading}
        isSuggestionsLoading={followSuggestionsLoading}
        me={me}
        meta={meta}
        myGamesIds={myGamesIds}
        router={router}
        onLogoClick={handleLogoClick}
        onRequestConnectGames={() => {
          if (!me) {
            return;
          }
          router.push(links.user.home(me.username));
        }}
        onRequestMore={handleRequestMore}
        onRequestRefetchEvents={handleRequestRefetchEvents}
      />
    );
  }

  return (
    <Desktop
      error={error}
      events={events}
      followSuggestions={followSuggestions}
      games={games}
      hasRenderedOnServer={hasRenderedOnServer}
      isLoading={isLoading}
      isSuggestionsLoading={followSuggestionsLoading}
      me={me}
      meta={meta}
      myGamesIds={myGamesIds}
      router={router}
      onClickActionLink={calendarEvent =>
        analytics.clickEventButton('groupFeed', calendarEvent, 'view')
      }
      onLogoClick={handleLogoClick}
      onRequestConnectGames={() => {
        if (!me) {
          return;
        }
        router.push(links.user.home(me.username));
        // onboardingModal.open({
        //   // initialGame: api.GameId.LeagueOfLegends,
        //   slides: [Slide.GameSettings],
        //   onSuccess: () => refetch({ iteratorOptions: { limit: 10, offset: 0 } }),
        // });
      }}
      onRequestMore={handleRequestMore}
      onRequestRefetchEvents={handleRequestRefetchEvents}
    />
  );
};
