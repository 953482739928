import { addHours, formatRFC3339 } from 'date-fns';

import { api } from 'api';
import { getTranslation } from 'components/Language';
import { links } from 'utils/links';

const EVENT_ORIGIN = 'https://lf.group';
const EVENT_PRICE = '0';
const ORGANIZER_NAME = 'LF Group Inc.';

function getEventFinishedTime(startTime: string) {
  return addHours(new Date(startTime), 2);
}

// Add more when needed
export interface MetaLdJson {
  '@context': 'http://schema.org';
  '@type': 'Event';
  eventAttendanceMode: 'https://schema.org/OnlineEventAttendanceMode';
  eventStatus: 'https://schema.org/EventScheduled';
  name: string;
  description: string;
  startDate: Date;
  endDate: Date;
  image: string[];
  offers: {
    '@type': 'Offer';
    url: string;
    price: string;
    validFrom: string;
    availability: 'https://schema.org/InStock';
  };
  performer: { '@type': 'PerformingGroup'; name: string };
  location: {
    '@type': 'VirtualLocation';
    url: string;
  };
  organizer: { '@type': 'Organization'; name: typeof ORGANIZER_NAME; url: string };
}

/**
 * Check out {@link https://developers.google.com/search/docs/data-types/event}
 * Test with {@link https://search.google.com/test/rich-results}
 */
export function getGroupMetaLdJson(event: api.Event): MetaLdJson {
  const url = escape(EVENT_ORIGIN + links.event(event.owner, event.id).pathname);

  const startDate = event.scheduledAt ? event.scheduledAt : event.createdAt;

  const text = getTranslation();

  return {
    '@context': 'http://schema.org',
    '@type': 'Event',
    eventAttendanceMode: 'https://schema.org/OnlineEventAttendanceMode',
    eventStatus: 'https://schema.org/EventScheduled',

    name: `LFG ${text.gameTitle(event.gameId as api.GameId).short} — ${escape(
      event.name,
    )}`,
    description: escape(event.description || ''),
    image: [event.backgroundUrl || ''],
    startDate,
    endDate: getEventFinishedTime(startDate),

    offers: {
      '@type': 'Offer',
      price: EVENT_PRICE,
      url,
      validFrom: formatRFC3339(new Date(startDate)),
      availability: 'https://schema.org/InStock',
    },
    performer: { '@type': 'PerformingGroup', name: escape(event.owner.username) },
    location: { '@type': 'VirtualLocation', url },
    organizer: { '@type': 'Organization', name: ORGANIZER_NAME, url: EVENT_ORIGIN },
  };
}

function escape(unsafe: string): string {
  return unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
}
