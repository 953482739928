import React, { useMemo, useState } from 'react';

import cx from 'classnames';

import { Icon } from 'components/Icons';
import { Text } from 'components/Text';
import { useText } from 'text';

import { WrapperStickyHeader } from '../../LayoutColumns';

import css from './WrapperGameStickyFilters.module.css';

interface WrapperGameStickyFiltersProps {
  children: React.ReactNode;
  renderMore?: React.ReactNode;
}

export const WrapperGameStickyFilters: React.FC<WrapperGameStickyFiltersProps> = ({
  children,
  renderMore,
}) => {
  const text = useText(state => state.controls.filters.actionMore);
  const [isExtended, setIsExtended] = useState<boolean>(false);

  const ShowMoreButton = useMemo<React.ReactNode>(() => {
    if (!renderMore) {
      return null;
    }

    return (
      <div className={css.showMoreContainer}>
        <button
          className={cx(css.showMoreButton, {
            [css.showMoreButtonActive]: isExtended,
          })}
          onClick={() => setIsExtended(!isExtended)}
        >
          <Text.R3>{isExtended ? text.showLess : text.showMore}</Text.R3>
          <Icon.ArrowDown className={css.showMoreIcon} />
        </button>
      </div>
    );
  }, [renderMore, isExtended]);

  return (
    <WrapperStickyHeader>
      <section className={css.container}>
        <div className={css.wrapperContainer}>
          {children}
          {ShowMoreButton}
        </div>
        {isExtended && renderMore ? (
          <div className={css.wrapperContainer}>{renderMore}</div>
        ) : null}
      </section>
    </WrapperStickyHeader>
  );
};
