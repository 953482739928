import { api } from 'api';

import type { SupportedGameId } from 'api/types';

export const EVENT_TITLE_MAX_LENGTH = 200;
export const EVENT_DESCRIPTION_MAX_LENGTH = 10000;

export const GAME_MODES_BY_GAME: Record<SupportedGameId, api.GameMode[]> = {
  WorldOfWarcraft: [
    api.GameMode.WowMythicPlus,
    api.GameMode.WowArena2v2,
    api.GameMode.WowArena3v3,
    api.GameMode.WowRaid,
    api.GameMode.WowRbg,
    api.GameMode.Custom,
  ],
  LostArkEn: [
    // api.GameMode.LostArkRaidsOnGuardians,
    api.GameMode.LostArkLegionRaids,
    api.GameMode.LostArkAbyssDungeons,
    // api.GameMode.LostArkArena,
    api.GameMode.LostArkRaidsAbyss,
    // api.GameMode.LostArkCube,
    // api.GameMode.LostArkBossRush,
    // api.GameMode.LostArkPlatinumFields,
    // api.GameMode.LostArkGuardianHeroicRaids,
    // api.GameMode.LostArkTrialsOfTheAbyss,
    api.GameMode.LostArkOther,
  ],
  LeagueOfLegends: [
    api.GameMode.LolClash,
    api.GameMode.LolDuo,
    api.GameMode.LolNormal,
    api.GameMode.LolAram,
    api.GameMode.LolCustomAram,
    api.GameMode.Custom,
  ],
  PUBGMobile: [api.GameMode.Custom],
  CODMobile: [api.GameMode.Custom],
  Standoff2: [api.GameMode.Custom],
  GooseGooseDuck: [api.GameMode.Custom],
  ShadowFight3: [api.GameMode.Custom],
  ShadowFight4Arena: [api.GameMode.Custom],
  DawnOfZombiesSurvival: [api.GameMode.Custom],
  Crossfire: [api.GameMode.Custom],
  NewStateMobile: [api.GameMode.Custom],
  RiseOfEmpires: [api.GameMode.Custom],
  ModernStrikeOnlinePvPFPS: [api.GameMode.Custom],
  RiseOfKingdomsLostCrusade: [api.GameMode.Custom],
  IdleHeroes: [api.GameMode.Custom],
  LastDayOnEarthSurvival: [api.GameMode.Custom],
  MobileLegendsAdventure: [api.GameMode.Custom],
  STALKER2HeartOfChornobyl: [api.GameMode.Custom],
  Highrise: [api.GameMode.Custom],
  AFKArena: [api.GameMode.Custom],
  CSGO: [],
  Custom: [],
  Hearthstone: [],
};

export const EVENTS_TYPE: Record<SupportedGameId, api.EventType[]> = {
  WorldOfWarcraft: [api.EventType.Post, api.EventType.Giveaway],
  LeagueOfLegends: [api.EventType.Post, api.EventType.Giveaway],
  CSGO: [api.EventType.Post, api.EventType.Giveaway, api.EventType.Event],
  PUBGMobile: [api.EventType.Post, api.EventType.Giveaway],
  CODMobile: [api.EventType.Post, api.EventType.Giveaway],
  Standoff2: [api.EventType.Post, api.EventType.Giveaway, api.EventType.Link],
  Hearthstone: [api.EventType.Post, api.EventType.Giveaway, api.EventType.Event],
  Custom: [api.EventType.Post, api.EventType.Giveaway, api.EventType.Event],
  LostArkEn: [api.EventType.Post, api.EventType.Giveaway],
  GooseGooseDuck: [api.EventType.Post, api.EventType.Giveaway, api.EventType.Event],
  ShadowFight3: [api.EventType.Post, api.EventType.Giveaway],
  ShadowFight4Arena: [api.EventType.Post, api.EventType.Giveaway],
  DawnOfZombiesSurvival: [api.EventType.Post, api.EventType.Giveaway],
  Crossfire: [api.EventType.Post, api.EventType.Giveaway],
  NewStateMobile: [api.EventType.Post, api.EventType.Giveaway],
  RiseOfEmpires: [api.EventType.Post, api.EventType.Giveaway],
  ModernStrikeOnlinePvPFPS: [api.EventType.Post, api.EventType.Giveaway],
  RiseOfKingdomsLostCrusade: [api.EventType.Post, api.EventType.Giveaway],
  IdleHeroes: [api.EventType.Post, api.EventType.Giveaway],
  LastDayOnEarthSurvival: [api.EventType.Post, api.EventType.Giveaway],
  MobileLegendsAdventure: [api.EventType.Post, api.EventType.Giveaway],
  STALKER2HeartOfChornobyl: [api.EventType.Post, api.EventType.Giveaway],
  Highrise: [api.EventType.Post, api.EventType.Giveaway],
  AFKArena: [api.EventType.Post, api.EventType.Giveaway],
};

export function canHaveWaitlist(event: api.EventInput) {
  return !(
    event.type === api.EventType.Link || event.type === api.EventType.Giveaway
  );
}
export function isScheduledToRequired(event: api.EventInput) {
  return event.type === api.EventType.Giveaway;
}
