import React from 'react';

import cx from 'classnames';
import { useRouter } from 'next/router';

import { Text } from 'components/Text';
import { useGameTabs } from 'layouts/Layout[Game]Feed/helpers';

import {
  ContentMobileTabs,
  getActiveTabLink,
} from 'layouts/LayoutColumns/ContentTabs';

import { Meta } from 'utils/meta';

import { LayoutMobileProps, LayoutMobile } from '../LayoutMobile';

import css from './LayoutMobile[Game]Feed.module.css';
import { Navigation } from './Navigation';

import type { api } from 'api';
import type { SupportedGameId } from 'api/types';

interface LayoutMobileGameFeedProps extends LayoutMobileProps {
  me: api.Maybe<api.User>;
  gameId: api.Maybe<SupportedGameId>;

  title: string;
  meta: Meta;

  transparentBackground?: boolean;

  onLogoClick?: (event: React.MouseEvent) => Promise<void> | void;
  onProfileClick?: (event: React.MouseEvent) => Promise<void> | void;
}

export const LayoutMobileGameFeed: React.FC<LayoutMobileGameFeedProps> = ({
  title,
  me,
  meta,
  gameId,
  onLogoClick,
  onProfileClick,
  children,
  transparentBackground,
}) => {
  const router = useRouter();
  const tabs = useGameTabs(gameId);

  return (
    <LayoutMobile
      me={me}
      meta={meta}
      onLogoClick={onLogoClick}
      onProfileClick={onProfileClick}
    >
      <section className={css.container}>
        <Text.H1 bold className={css.headerText}>
          {title}
        </Text.H1>
        <Navigation myGames={me?.games || []} pathname={router.pathname} />
        {/* Hide on home page */}
        {gameId && (
          <header className={css.mobileGameFeedTabsContainer}>
            <ContentMobileTabs
              activeTab={getActiveTabLink(tabs, router)}
              tabs={tabs}
            />
          </header>
        )}
        <section
          className={cx(css.wrapper, {
            [css.wrapperPlayers]: !transparentBackground,
          })}
        >
          {children}
        </section>
      </section>
    </LayoutMobile>
  );
};
