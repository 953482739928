import React, { useState, useEffect } from 'react';

import { useRouter } from 'next/router';

import { api, useMe } from 'api';

import { utils } from 'utils';

// function getRandomInt(max: number) {
//   return Math.floor(Math.random() * max);
// }

// function getRandomSlot(group: api.Event): api.Slot | undefined {
//   const freeSlots = group?.slots?.filter(slot => !slot.user);
//   const slotNumber = freeSlots && getRandomInt(freeSlots.length);
//   const randomSlot = freeSlots && slotNumber ? freeSlots[slotNumber] : undefined;

//   return randomSlot;
// }

export const OnboardingCardList: React.FC = () => {
  const router = useRouter();
  const me = useMe();

  const getOffset = () => utils.getQueryInt(router, 'offset');

  const notitficationsQuery = api.useGetMyInvitesQuery({
    variables: {
      options: { offset: getOffset(), limit: 100 },
    },
    // fetchPolicy: fetchPolicySSR(isSsr),
    notifyOnNetworkStatusChange: true,
  });

  const myInvites = notitficationsQuery.data?.getMyInvites.data as api.Event[];

  const [invites, setInvites] = useState(myInvites?.slice(0, 3));
  const [activeIndex] = useState(0);
  const [, setIsDefaultCardOpen] = useState<boolean>(!!myInvites?.length);

  useEffect(() => {
    setInvites(myInvites?.slice(activeIndex, activeIndex + 3));
  }, [myInvites]);

  useEffect(() => {
    if (!myInvites || !invites) {
      return;
    }

    if (activeIndex < myInvites.length) {
      return;
    }

    const timeout = setTimeout(() => {
      setIsDefaultCardOpen(false);
    }, 1500);

    return () => {
      clearTimeout(timeout);
    };
  }, [activeIndex]);

  // function onSwipeCard() {
  //   if (!myInvites || !invites) {
  //     return;
  //   }

  //   setActiveIndex(activeIndex + 1);

  //   if (!myInvites[activeIndex + 3] || !myInvites[activeIndex + 2]) {
  //     return;
  //   }

  //   setInvites([...invites, myInvites[activeIndex + 3]]);
  // }

  if (!me) {
    return null;
  }
  if (!myInvites?.length || !invites?.length || activeIndex > invites.length - 1) {
    return null;
  }

  return null;
  // return (
  //   <div className={css.onboardingCardListContainer}>
  //     {invites.map((notification, index) => {
  //       return (
  //         <OnboardingCard
  //           key={index}
  //           inviteNotification={notification}
  //           className={cx({
  //             [css.firstCard]: index <= activeIndex,
  //           })}
  //           onAccept={async () => {
  //             events.clickAcceptInvite('rightBar', notification.id);
  //             const randomSlot = getRandomSlot(notification);
  //             await catchToToast(
  //               approveInvite({
  //                 variables: {
  //                   groupId: notification.id,
  //                   slotId: randomSlot?.id || '',
  //                 },
  //               }),
  //             );

  //             onSwipeCard();
  //           }}
  //           onDecline={async () => {
  //             events.clickRejectInvite('rightBar', notification.id);
  //             await catchToToast(
  //               leaveGroup({ variables: { groupId: notification.id } }),
  //             );

  //             onSwipeCard();
  //           }}
  //           style={{ zIndex: invites.length - index + 2 }}
  //         />
  //       );
  //     })}
  //     <div
  //       className={cx(css.onboardingCardContainer, css.defaultCard, {
  //         [css.firstCard]: activeIndex === invites.length,
  //         [css.hiddenCard]: !isDefaultCardOpen || activeIndex !== invites.length,
  //       })}
  //     >
  //       {text.noInvites}
  //     </div>
  //   </div>
  // );
};
