import React, { useCallback, useMemo, useState } from 'react';

import cx from 'classnames';

import Link from 'next/link';

import { api, useMe } from 'api';

import { AvatarUser } from 'components/Avatar';

import { CardDescription } from 'components/Card';
import { Icon } from 'components/Icons';
import { LoaderBouncing } from 'components/Loaders';
import { Text } from 'components/Text';
import { ButtonFollowUser } from 'components/UserCard';

import { useText } from 'text';

import { links } from 'utils/links';

import css from './FollowSuggestionsBanner.module.css';

export interface FollowSuggestionsBannerProps {
  followSuggestions: api.User[];
  wrapperClassName?: string;
  canBeHidden?: boolean;
  isLoading?: boolean;
  hideTitle?: boolean;
  showFullList?: boolean;

  onRequestComplete?: (userId: string, isFollowing: boolean) => void;
  onRequestRefetchEvents?: () => void | Promise<void>;
}

const LIST_MIN_ELEMENTS = 3;
const STORAGE_KEY = 'suggestionsBannerState';

export const FollowSuggestionsBanner: React.FC<FollowSuggestionsBannerProps> = ({
  followSuggestions,
  wrapperClassName,
  canBeHidden = false,
  isLoading = false,
  hideTitle = false,
  showFullList = false,

  onRequestRefetchEvents = () => undefined,
  onRequestComplete = () => undefined,
}) => {
  const me = useMe();
  const userSearchText = useText(state => state.userSearch.card);
  const controlsText = useText(state => state.controls);
  const bannerText = useText(state => state.rightColumn.suggestions);
  const listLength = showFullList ? 10 : LIST_MIN_ELEMENTS;
  const [isUpdateButtonActive, setIsUpdateButtonActive] = useState<boolean>(false);
  const [isHidden, setIsHidden] = useState<boolean>(() => {
    if (!canBeHidden) {
      return false;
    }
    if (typeof sessionStorage !== 'undefined') {
      return sessionStorage.getItem(STORAGE_KEY) === 'true';
    }

    return false;
  });

  const hideBanner = useCallback(() => {
    sessionStorage.setItem(STORAGE_KEY, 'true');
    setIsHidden(true);
  }, []);

  const hasMore = useMemo(() => {
    if (showFullList) {
      return false;
    }

    return followSuggestions.length > LIST_MIN_ELEMENTS;
  }, [followSuggestions.length]);

  if (!me || isHidden || !followSuggestions.length) {
    return null;
  }

  if (isLoading) {
    return (
      <div
        className={cx(
          css.followSuggestionsBannerWrapper,
          css.loaderWrapper,
          wrapperClassName,
        )}
      >
        <LoaderBouncing />
      </div>
    );
  }

  return (
    <div className={cx(css.followSuggestionsBannerWrapper, wrapperClassName)}>
      {canBeHidden && <Icon.Close className={css.closeIcon} onClick={hideBanner} />}
      {!hideTitle && (
        <Text.R3Memo semibold className={css.title}>
          {bannerText.title}
        </Text.R3Memo>
      )}
      {!hideTitle && (
        <Text.R4Memo className={css.description}>
          {bannerText.description}
        </Text.R4Memo>
      )}
      <div className={css.followSuggestionsList}>
        {followSuggestions.slice(0, listLength).map(user => {
          return (
            <div key={user.id} className={css.suggestionUser}>
              <AvatarUser hideOnline className={css.avatarContainer} user={user} />
              <div className={css.userInfo}>
                <Link
                  className={css.usernameLink}
                  href={links.user.home(user.username)}
                >
                  <Text.R3 semibold className={css.username}>
                    @{user.username}
                  </Text.R3>
                  {user.roles?.includes(api.UserRole.Streamer) ? (
                    <Icon.StreamerCheck className={css.streamerCheck} />
                  ) : null}
                </Link>
                <div className={css.userAdditionalInfo}>
                  <Text.R3 className={css.followersCount}>
                    {userSearchText.followers(user.followersCount || 0)}
                  </Text.R3>
                </div>
                <CardDescription
                  description={user.description}
                  TextFormatter={Text.R2Memo}
                />
              </div>
              <ButtonFollowUser
                className={css.followButton}
                isRefetchEvents={false}
                playerEventCategory="profileBlock"
                user={user}
                onComplete={follow => {
                  onRequestComplete(user.id, follow);
                  if (follow && !isUpdateButtonActive) {
                    setIsUpdateButtonActive(true);
                  }
                }}
              />
            </div>
          );
        })}
      </div>
      {hasMore && (
        <Link href={links.user.suggestions(me?.username)}>
          <button className={css.showMoreButton} color="secondary">
            {controlsText.actions.showMore}
          </button>
        </Link>
      )}
    </div>
  );
};
