import React, { useMemo } from 'react';

/* eslint-disable @typescript-eslint/ban-types */
/**
 * !IMPORTANT:
 * Data flow in NextJS app:
 * 1. Every next page (@type NextPage) is rendered inside a global layout called App (pages/_app.tsx)
 * 2. During SSR, App fetches user info (such as preferred language) via App.getInitialProps
 * 3. App can pass data (@type PageGloabalProps) as props to every rendered page (@type NextPage)
 * 4. Every page (@type NextPage) can fetch its own server-side data via Page.getInitialProps
 * 5. After a page (@type NextPage) has collected it data it passes it as a props to a container (@type ContainerPage)
 * 6. ContainerPage is a controller to 3 separate views: desktop component, mobile component and page meta
 */

import { BaseContext } from 'next/dist/shared/lib/utils';

import { api, COOKIE_AUTH_TOKEN_KEY } from 'api';

import { cookie } from './main';

import type {
  ApolloClient,
  NormalizedCacheObject,
  WatchQueryFetchPolicy,
} from '@apollo/client';
import type {
  NextComponentType,
  NextPage as NextPageType,
  NextPageContext,
} from 'next';

export type HashMap = Record<string, unknown>;

/**
 * useDeviceBasedComponent
 * @param isMobile if true, mobile component will be returned
 * @param components an object that takes 2 components: for mobile or for desktop render
 * @returns memoized version of one of provided components
 */
export function useDeviceBasedComponent<P>(
  isMobile: boolean,
  components: {
    mobile?: React.ComponentType<P>;
    desktop: React.ComponentType<P>;
  },
): React.ComponentType<P> {
  return useMemo(() => {
    if (!components.mobile) {
      return components.desktop;
    }

    return isMobile ? components.mobile : components.desktop;
  }, [isMobile]);
}

/**
 * PageGlobalProps is a set of props
 * that are being passed to every {@type NextPage} from the Next _app
 *
 * Find references in _app to comprehend better
 */
export interface PageGloabalProps {
  hasRenderedOnServer: boolean;
  isMobile: boolean;
  hydratedFetchPolicy: WatchQueryFetchPolicy;
}

export interface PageApolloProps {
  apolloClient: ApolloClient<NormalizedCacheObject>;
  apolloState: NormalizedCacheObject;
}

export type ContainerProps<P = {}> = PageGloabalProps & PageApolloProps & P;
export type ContainerPage<P = {}> = React.ComponentType<ContainerProps<P>>;
export type NextPage<P = HashMap, IP = P> = NextPageType<ContainerProps<P>, IP>;

export interface NextApolloContext extends NextPageContext {
  apolloClient: ApolloClient<NormalizedCacheObject>;
}

export function getApolloClientFromCtx(
  ctx: NextPageContext,
): ApolloClient<NormalizedCacheObject> {
  const { apolloClient } = ctx as NextApolloContext;

  return apolloClient;
}

export function getMeFromApolloFromClientCtx(
  ctx: NextPageContext,
): api.Maybe<api.GetMeQuery['getMe']> {
  const client = getApolloClientFromCtx(ctx);
  const me = client.readQuery<api.GetMeQuery>({ query: api.GetMeDocument });

  return me?.getMe;
}

// AnyNextPage is a generic type of every next page, that is being rendered in Next App (pages/_app)
export type AnyNextPage<
  C extends BaseContext = NextPageContext,
  IP = {},
  P extends PageGloabalProps = PageGloabalProps,
> = NextComponentType<C, IP, P>;

// newPage casts ContainerPage to NextPage
export function newPage<P>(Component: ContainerPage<P>): NextPage<P> {
  return (props: ContainerProps<P>) => {
    return <Component {...props} />;
  };
}

export function getTokenFromCtxOrClient(ctx: NextPageContext): string | undefined {
  const req = ctx.req;
  if (!req?.headers?.cookie) {
    return cookie.get(COOKIE_AUTH_TOKEN_KEY);
  }

  return cookie.get(COOKIE_AUTH_TOKEN_KEY, req.headers.cookie);
}

export type PlatformFn<T> = {
  server: () => T;
  client: () => T;
};
